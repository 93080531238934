import { SET_NEWSLETTER  } from '../actions/newsletter'

const initState = {
    emails: []
}
  
export default function tagsReducer (state = initState, action) {
    switch (action.type) {
      case SET_NEWSLETTER:
            return {
              emails: action.payload
        }
    
      default:
        return state
    }
  }
  

import React, { useState } from 'react'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill'
import { Input, Row, Col, Button, Icon, Select, Upload, message } from 'antd'
import { API_UPLOAD} from '../../constants'
import {fetchAddAArticle} from '../../actions/articles'
import 'react-quill/dist/quill.snow.css';
const { Option } = Select 
const { Dragger } = Upload;



function AddNewArticle({ fetchAddAArticle, token, cities }) {
  const tags= ['url', 'news', 'events', 'stats', 'publications', 'documentations', 'com_press', 'rev_press']
    const [article, setArticle] = useState({
      title: null,
      content: null,
      featuredImage: null,
      attachment: null,
      tags: [],
      status: null,
      city: null
    });

 
    // media uploads
    const dropSettingsMedia = {
      name: 'file',
      multiple: false,
      action: API_UPLOAD,
      onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
          //console.log(info.file, info.fileList);
        }
        if (status === 'done') {
          setArticle({...article, featuredImage:  info.file.response.url })
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
  };

  // media uploads
  const dropSettingsAttachment = {
    name: 'file',
    multiple: false,
    action: API_UPLOAD,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        //console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setArticle({...article, attachment:  info.file.response.url })
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
};

  
  

    return (
      <div>
        <Row gutter={16}>
          <h2>
            <Icon type="edit" /> Add a new Article
          </h2>
          <Col md={18}>
          <Col md={12}>
            
          <Input
            placeholder={"Title"}
            onChange={event => setArticle({...article, title:event.target.value})}
          />
      
      </Col>
     
           
            <Col md={12}>
            <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Tags"
            onChange={t =>  setArticle({...article, tags:t})}
          >
            {tags &&
              tags[0] &&
              tags.map((t, k) => (
                <Option key={t} value={t}>
                  {t}
                </Option>
              ))}
        </Select>
        
           
        
      </Col>
     
            <Col md={24}>
        
            <ReactQuill value={article.content}
            onChange={(content)=>setArticle({...article, content: content})} />
        
      </Col>
      
      <Button type="primary" block size={'large'} onClick={() => {
        fetchAddAArticle(token, article)
        //console.log(article)
      }}>Add</Button>
            
          </Col>





          <Col md={6}>
          <Col md={24}>
        
          <Select
          defaultValue={"draft"}
          style={{ width: 120 }}
          onSelect={event => setArticle({...article, status:event})}
        >
          <Option value="draft">Draft</Option>
          <Option value="published">Published</Option>
            </Select>
            
            
          
            </Col>
            
            <Col md={24}>
            <Select
            style={{ width: "100%" }}
            placeholder="City"
            onChange={t =>  setArticle({...article, city:t})}
          >
            {cities &&
              cities[0] &&
              cities.map((t, k) => (
                <Option key={t.slug} value={t.slug}>
                  {t.slug}
                </Option>
              ))}
        </Select>
        
           
        
      </Col>
          
            <Col md={24}>
              
          
        
     
        
          <Dragger {...dropSettingsMedia}>
          <p className="ant-upload-drag-icon">
            <Icon type="camera" />
          </p>
          <p className="ant-upload-text">
            Add a featured image
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload.
          </p>
              </Dragger>
              
              <div style={{paddingBottom:'20px'}}></div>
              <Dragger {...dropSettingsAttachment}>
              <p className="ant-upload-drag-icon">
                <Icon type="paper-clip" />
              </p>
              <p className="ant-upload-text">
                Add an Attachment
              </p>
              <p className="ant-upload-hint">
                Support for a single upload.
              </p>
            </Dragger>
    
          </Col>
          
          </Col>
        
            </Row>
        
      </div>
    );
}


const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
  ...state.Articles, 
    ...state.Cities
  })
  
const mapDispatchToProps = dispatch => ({
  fetchAddAArticle: (token, data) => dispatch(fetchAddAArticle(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNewArticle)
  
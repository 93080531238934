import React, {useState} from 'react'
import { connect } from 'react-redux'
import { List, Row, Col, Popconfirm, Drawer, Icon } from 'antd';
import Spacer from '../../components/Spacer'
import Layout from '../../components/Layout'
import AddNewArticle from './AddNew'
import EditArticle from './Edit'
import {fetchRemoveAArticle} from '../../actions/articles'
import './articles.css'

function Articles({ articles, token, fetchRemoveAArticle }) {
  const [editedItem, setEditedItem] = useState(null)
  const[drawerVisible, setDrawerVisible] = useState(false)
    return (
      <Layout>
        <Row gutter={50}>
          <Col md={6}>
            <List
              bordered
              dataSource={articles}
              renderItem={item => (
                <List.Item>
                 
                  <div style={{ cursor: 'pointer' }} onClick={() => {
                    setEditedItem(item)
                    setDrawerVisible(true)
                  }}>
                     
                        <h3>{item.title}</h3>
                        
                   
                    
                    </div>
                    
                 
                  <Popconfirm
                  title="Are you sure delete this article?"
                onConfirm={() => {
                    fetchRemoveAArticle(token, item._id)
                }}
                  okText="Yes"
                  cancelText="No"
                >
                    <div style={{position:'absolute', right:'20px', cursor:'pointer' }}><Icon type="delete" style={{fontSize:'20px'}} /></div>
                    </Popconfirm>
                </List.Item>
              )}
            />

           
            
          </Col>
          <Col md={18}>
          
            

            <AddNewArticle/>
          </Col>
          
         
          
          

            </Row>
        <Spacer top />
        

        <Drawer
          placement="right"
          width={'70vw'}
          destroyOnClose={true}
          closable={false}
          onClose={()=>{setDrawerVisible(false)}}
          visible={drawerVisible}
        >
        <EditArticle data={editedItem}/>
        </Drawer>
        

      </Layout>
    );
}

const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
    ...state.Articles
  })
  
const mapDispatchToProps = dispatch => ({
  fetchRemoveAArticle: (token, data) => dispatch(fetchRemoveAArticle(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Articles)
  
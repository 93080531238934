import { API_INSTAWALL, JSON_HEADERS } from '../constants'
import { message } from 'antd';

export const SET_INSTAWALL_IMAGES = 'SET_INSTAWALL_IMAGES'
export const ADD_INSTAWALL_ONE_IMAGE = 'ADD_INSTAWALL_ONE_IMAGE'

// setAuthToken
function setInstaWallImages(cities) {
    return {
        type: SET_INSTAWALL_IMAGES,
        payload: cities
    }
}

function addInstawallImage(city) {
    return {
        type: ADD_INSTAWALL_ONE_IMAGE,
        payload: city
    }
}


// get InstaWallImages from the server
export function fetchSetInstaWallImages(token) {
    return function (dispatch) {
        return fetchApiGetInstaWallImages(token)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setInstaWallImages(response.data))
                    } else {
                        message.error('Something bad happens. Code: INSTA01')
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: INSTA02'))
    }
    
}

function fetchApiGetInstaWallImages(token) {
    return fetch(API_INSTAWALL,{
        method: "GET",
        headers: {...JSON_HEADERS, Authorization: "Bearer "+ token},
    })
}

// Add a new place
function fetchApiPostInstawallImage(token, data) {
    if (token && data) {
        return fetch(API_INSTAWALL,{
            method: "POST",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }
}

export function fetchAddAInstawallImage(token, data) {
    return function (dispatch) {
        return fetchApiPostInstawallImage(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(addInstawallImage(response.data))
                        message.success('This city has been added successfuly')
                    } else {
                        message.error('Something bad happens. Code: CA01')
                    }
                })
            .catch(e => {
                console.log(e)
                message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: CA02')
            })
    }
}

// Remove a Tag
function fetchApiDeletePlace(token, tagId) {
    if (token && tagId) {
        return fetch(API_INSTAWALL+'/'+tagId,{
            method: "DELETE",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchRemoveAInstawallImage(token, tagId) {
    return function (dispatch) {
        return fetchApiDeletePlace(token, tagId)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(fetchSetInstaWallImages(token))
                        message.success('This city has been removed successfuly')
                    } else {
                        message.error('Something bad happens. Code: CD01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: CD02'))
    }
}

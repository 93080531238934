import React, { useState } from 'react'
import {connect} from 'react-redux'
import { Input, Row, Col, Button, Icon, Upload, message, Avatar, Popconfirm } from 'antd'
import { fetchAddAInstawallImage } from '../../actions/instawall'
import Spacer from '../../components/Spacer'
import {API_UPLOAD} from '../../constants'

const { Dragger } = Upload;

function AddNewTag({fetchAddAInstawallImage, token}) {
  const [url, setUrl] = useState()
  const [media, setMedia] = useState([])
  const handleRemoveMedia = (mediaUrl) => {
    setMedia(media.filter(m=>m.url !== mediaUrl))
  }


   // media uploads
   const dropSettings = {
    name: 'file',
    multiple: false,
    action: API_UPLOAD,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        //console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setMedia([...media, info.file.response ])
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

    return (
      <div>
        <Row gutter={16}>
          <h2>
            <Icon type="edit" /> Add a new Instagram photo
          </h2>
        
         
          <Spacer top='50px' />
          <h1>URL</h1>
          <Col md={24}>
            <Input
              placeholder={"https://instagram.com/...."}
              onChange={event => setUrl(event.target.value)}
            />
          </Col>
         
        </Row>
        
        
        <Spacer top='20px' />
        <Row>
          <Col>
          <h1>Image</h1>
          <Dragger {...dropSettings}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single upload
            </p>
          </Dragger>

         
          {(media && media[0] && media[0].url)&&
            media.map((m, k) => (
              <Popconfirm
                placement="topLeft"
                title={"Delete this image?"}
                onConfirm={() => handleRemoveMedia(m.url)}
                okText="Yes"
                cancelText="No"
                key={m.url}
              >
                <Avatar
                  size={64}
                  src={m.url}
                  style={{ cursor: "pointer", margin: "20px" }}
                />
              </Popconfirm>
            ))}
            </Col>
        </Row>
        

        <Button
          type="primary"
          block
          size={"large"}
          onClick={() =>
            fetchAddAInstawallImage(token, {
              url,
              media
            })
          }
        >
          Add
        </Button>
      </div>
    );
}


const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
    ...state.Instawall
  })
  
const mapDispatchToProps = dispatch => ({
  fetchAddAInstawallImage: (token, data) => dispatch(fetchAddAInstawallImage(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNewTag)
  
import React, { useState } from 'react'
import {connect} from 'react-redux'
import {slugify} from '../../constants'
import { Input, Row, Col, Button, Icon, message } from 'antd'
import {fetchAddATag} from '../../actions/tags'


const prepareDataBeforesend = (tagName, order=0) => {
    if (!tagName || !tagName.fr) {
        message.error('All fields are required :)')
        return
    }
  
    return {
        slug: slugify(tagName.fr),
      name: tagName,
        order: order
    }
}

function AddNewTag({fetchAddATag, token}) {
  const [tagName, setTagName] = useState({ fr: null, en: null, es: null, ar: null, zh: null })
  const [order, setOrder] = useState(0)


    return (
      <div>
        <Row gutter={16}>
          <h2>
            <Icon type="edit" /> Add a new Tag
          </h2>
          <Col md={24}>
            
          <Input
            placeholder={"Order"}
            onChange={event => setOrder(event.target.value)}
          />
      
          </Col>
          
          <Col md={24}>
            
              <Input
                placeholder={"Français"}
                onChange={event => setTagName({...tagName, fr:event.target.value})}
              />
          
          </Col>
          <Col md={24}>
            
              <Input
                placeholder={"English"}
                onChange={event => setTagName({...tagName, en:event.target.value})}
              />
          
                </Col>
                <Col md={24}>
            
              <Input
                placeholder={"española"}
                onChange={event => setTagName({...tagName, es:event.target.value})}
              />
          
                </Col>
                <Col md={24}>
            
                <Input
                  placeholder={"عربى"}
                  onChange={event => setTagName({...tagName, ar:event.target.value})}
                />
            
                </Col>
                <Col md={24}>
            
                <Input
                  placeholder={"Chinese"}
                  onChange={event => setTagName({...tagName, zh:event.target.value})}
                />
            
                </Col>
                
            </Row>
            <Button type="primary" block size={'large'} onClick={() => fetchAddATag(token, prepareDataBeforesend(tagName, order))}>Add</Button>
      </div>
    );
}


const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
    ...state.Tags
  })
  
const mapDispatchToProps = dispatch => ({
  fetchAddATag: (token, data) => dispatch(fetchAddATag(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNewTag)
  
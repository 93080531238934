import { SET_INSTAWALL_IMAGES, ADD_INSTAWALL_ONE_IMAGE  } from '../actions/instawall'

const initState = {
    instawall: []
}
  
export default function tagsReducer (state = initState, action) {
    switch (action.type) {
      case SET_INSTAWALL_IMAGES:
            return {
              instawall: action.payload
        }
      case ADD_INSTAWALL_ONE_IMAGE:
        return {
          instawall: [...state.instawall, action.payload]
        }
    
      default:
        return state
    }
  }
  

import Auth from './auth'
import Places from './places'
import Tags from './tags'
import Tours from './tours'
import Cities from './city'
import Mice from './mice'
import Articles from './articles'
import Stories from './stories'
import Circuits from './circuits'
import Newsletter from './newsletter'
import Instawall from './instawall'

const reducers =  {
    Auth,
    Places,
    Tags,
    Tours,
    Cities,
    Mice,
    Articles,
    Stories,
    Circuits, //hiking
    Newsletter,
    Instawall
}

export default reducers
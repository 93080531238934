import React, {useState} from 'react'
import { connect } from 'react-redux'
import {fetchRemoveACity} from '../../actions/city'
import { List, Row, Col, Popconfirm, Drawer, Icon } from 'antd';
import Spacer from '../../components/Spacer'
import Layout from '../../components/Layout'
import AddNewCity from './AddNew'
import EditCity from './Edit'

import './cities.css'


function Tags({ cities, token, fetchRemoveACity }) {
  const [editedItem, setEditedItem] = useState(null)
  const[drawerVisible, setDrawerVisible] = useState(false)
    return (
      <Layout>
        <Row gutter={50}>
          <Col md={6}>
            <List
              bordered
              dataSource={cities}
              renderItem={item => (
                <List.Item>
                 
                  <div style={{ cursor: 'pointer' }} onClick={() => {
                    setEditedItem(item)
                    setDrawerVisible(true)
                  }}>
                      
                        <h3>{item.title.fr}</h3>
                        
                  
                    
                    </div>
                    
                 
                    <Popconfirm
                    title="Are you sure delete this tag?"
                  onConfirm={() => {
                      fetchRemoveACity(token, item._id)
                  }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <div style={{ position: 'absolute', right: '20px', cursor: 'pointer' }}><Icon type="delete" style={{ fontSize: '20px' }} /></div>
                    </Popconfirm>
                   
                </List.Item>
              )}
            />

           
            
          </Col>
          <Col md={12}>
          
            

            <AddNewCity/>
          </Col>
          
         
          
          

            </Row>
        <Spacer top />
        

        <Drawer
          placement="right"
          width={'70vw'}
          destroyOnClose={true}
          closable={false}
          onClose={()=>{setDrawerVisible(false)}}
          visible={drawerVisible}
        >
        <EditCity data={editedItem}/>
        </Drawer>
        

      </Layout>
    );
}

const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
  ...state.Tags,
    ...state.Cities
  })
  
const mapDispatchToProps = dispatch => ({
  fetchRemoveACity: (token, id)=>dispatch(fetchRemoveACity(token, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Tags)
  
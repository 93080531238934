import { API_STORIES, JSON_HEADERS } from '../constants'
import { message } from 'antd';

export const SET_STORIES = 'SET_STORIES'
export const ADD_STORY = 'ADD_STORY'
export const UPDATE_STORY = 'UPDATE_STORY'

// setAuthToken
function setStories(stories) {
    return {
        type: SET_STORIES,
        payload: stories
    }
}

function addStory(story) {
    return {
        type: ADD_STORY,
        payload: story
    }
}


// get Stories from the server
export function fetchSetStories(token) {
    return function (dispatch) {
        return fetchApiGetStories(token)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setStories(response.data))
                    } else {
                        message.error('Something bad happens. Code: STR01')
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: STRE02'))
    }
    
}

function fetchApiGetStories(token) {
    return fetch(API_STORIES,{
        method: "GET",
        headers: {...JSON_HEADERS, Authorization: "Bearer "+ token},
    })
}


// Update a place
function fetchApiPutStory(token, data) {
    if (token && data && data._id) {
        return fetch(API_STORIES+'/'+data._id,{
            method: "PUT",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchUpdateAStory(token, data) {
    return function (dispatch) {
        return fetchApiPutStory(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(fetchSetStories(token))
                        message.success('This place has been updated successfuly')
                    } else {
                        message.error('Something bad happens. Code: STROU01')
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: STROE02'))
    }
}

// Add a new place

function fetchApiPostStory(token, data) {
    if (token && data) {
        return fetch(API_STORIES,{
            method: "POST",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchAddAStory(token, data) {
    return function (dispatch) {
        return fetchApiPostStory(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(addStory(response.data))
                        message.success('This story has been added successfuly')
                    } else {
                        message.error('Something bad happens. Code: CA01')
                    }
                })
            .catch(e => {
                console.log(e)
                message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: CA02')
            })
    }
}

// Remove a Tag
function fetchApiDeletePlace(token, tagId) {
    if (token && tagId) {
        return fetch(API_STORIES+'/'+tagId,{
            method: "DELETE",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchRemoveAStory(token, tagId) {
    return function (dispatch) {
        return fetchApiDeletePlace(token, tagId)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(fetchSetStories(token))
                        message.success('This story has been removed successfuly')
                    } else {
                        message.error('Something bad happens. Code: CD01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: CD02'))
    }
}

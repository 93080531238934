import React, { useState } from 'react'
import {connect} from 'react-redux'
import { Input, Row, Col, Button, Icon, Upload, message, Avatar, Popconfirm } from 'antd'
import { fetchUpdateACity } from '../../actions/city'
import Spacer from '../../components/Spacer'
import { API_UPLOAD} from '../../constants'



const {TextArea} = Input
const { Dragger } = Upload;



function EditTag({fetchUpdateACity, token, data}) {
  const [tagName, setTagName] = useState({ ...data })
  const [media, setMedia] = useState(tagName?.media)

  
const handleRemoveMedia = (mediaUrl) => {
  setMedia(media.filter(m=>m.url !== mediaUrl))
}


 // media uploads
 const dropSettings = {
  name: 'file',
  multiple: false,
  action: API_UPLOAD,
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      //console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      setMedia([info.file.response ])
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

  
  
    return (
      <div>
      <Row gutter={16}>
      <h2>
            <Icon type="edit" /> Edit
          
      </h2>
      <Spacer top='50px' />
      <h1>Display order</h1>
      <Input
      defaultValue={tagName.order}
  placeholder={"Order"}
      onChange={event => setTagName({ ...tagName, order: event.target.value })}
/>
      <Spacer top='50px' />
      <h1>Title</h1>
      <Col md={8}>
            <Input
              defaultValue={tagName.title.fr}
          placeholder={"Français"}
              onChange={event => setTagName({ ...tagName, title: {...tagName.title,fr: event.target.value} })}
        />
      </Col>
      <Col md={8}>
            <Input
            defaultValue={tagName.title.en}
          placeholder={"English"}
          onChange={event => setTagName({ ...tagName, title: {...tagName.title,en: event.target.value} })}
        />
      </Col>
      <Col md={8}>
            <Input
            defaultValue={tagName.title.es}
          placeholder={"española"}
          onChange={event => setTagName({ ...tagName, title: {...tagName.title,es: event.target.value} })}

        />
      </Col>
      <Col md={8}>
            <Input
            defaultValue={tagName.title.ar}
          placeholder={"عربى"}
          onChange={event => setTagName({ ...tagName, title: {...tagName.title,ar: event.target.value} })}

        />
      </Col>
      <Col md={8}>
            <Input
            defaultValue={tagName.title.zh}
          placeholder={"Chinese"}
          onChange={event => setTagName({ ...tagName, title: {...tagName.title,zh: event.target.value} })}

        />
      </Col>
    </Row>
    <Spacer top='20px' />
    <Row gutter={16}>
    
      <h1>Description</h1>

     

      <Col md={24}>
            <TextArea
            defaultValue={tagName.description.fr}
          placeholder={"Français"}
          onChange={event => setTagName({ ...tagName, description: {...tagName.description,fr: event.target.value} })}
        />
      </Col>
      <Col md={24}>
            <TextArea
            defaultValue={tagName.description.en}
          placeholder={"English"}
          onChange={event => setTagName({ ...tagName, description: {...tagName.description,en: event.target.value} })}
        />
      </Col>
      <Col md={24}>
            <TextArea
            defaultValue={tagName.description.es}
          placeholder={"española"}
          onChange={event => setTagName({ ...tagName, description: {...tagName.description,es: event.target.value} })}
        />
      </Col>
      <Col md={24}>
            <TextArea
            defaultValue={tagName.description.ar}
          placeholder={"عربى"}
          onChange={event => setTagName({ ...tagName, description: {...tagName.description,ar: event.target.value} })}
        />
      </Col>
      <Col md={24}>
            <TextArea
            defaultValue={tagName.description.zh}
          placeholder={"Chinese"}
          onChange={event => setTagName({ ...tagName, description: {...tagName.description,zh: event.target.value} })}
        />
      </Col>

    </Row>
    <Spacer top='20px' />
    <Row gutter={16}>
      <h1>Position</h1>
    <Col md={8}>
            <Input
            defaultValue={tagName.coordinates.latitude}
      placeholder={"Latitude"}
      onChange={event => setTagName({ ...tagName, coordinates: {...tagName.coordinates,latitude: event.target.value} })}
      />
</Col>
<Col md={8}>
            <Input
            defaultValue={tagName.coordinates.longitude}
    placeholder={"Longitude"}
    onChange={event => setTagName({ ...tagName, coordinates: {...tagName.coordinates,longitude: event.target.value} })}
    />
</Col>
        </Row>
        
        <Spacer top='20px' />
        <Row>
          <Col>
          <h1>Images</h1>
          <Dragger {...dropSettings}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Dragger>

         
          {(media && media[0] && media[0].url)&&
            media.map((m, k) => (
              <Popconfirm
                placement="topLeft"
                title={"Delete this image?"}
                onConfirm={() => handleRemoveMedia(m.url)}
                okText="Yes"
                cancelText="No"
                key={m.url}
              >
                <Avatar
                  size={64}
                  src={m.url}
                  style={{ cursor: "pointer", margin: "20px" }}
                />
              </Popconfirm>
            ))}
            </Col>
        </Row>


        <Button type="primary" block size={'large'} onClick={() => {
          console.log('query', {...tagName, media})
          fetchUpdateACity(token,  {...tagName, media})
        }}>Update</Button>
      </div>
    );
}


const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
    ...state.Tags
  })
  
const mapDispatchToProps = dispatch => ({
  fetchUpdateACity: (token, data) => dispatch(fetchUpdateACity(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditTag)
  
import { API_NEWSLETTER, API_NEWSLETTER_SEND, JSON_HEADERS } from '../constants'
import { message } from 'antd';

export const SET_NEWSLETTER = 'SET_NEWSLETTER'



function setEmails(tags) {
    return {
        type: SET_NEWSLETTER,
        payload: tags
    }
}


// get tags from the server
export function fetchSetEmails(token) {
    return function (dispatch) {
        return fetchApiGetEmails(token)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setEmails(response.data))
                    } else {
                        message.error('Something bad happens. Code: TE01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: TE02'))
    }
    
}

function fetchApiGetEmails(token) {
    return fetch(API_NEWSLETTER ,{
        method: "GET",
        headers: {...JSON_HEADERS, Authorization: "Bearer "+ token},
    })
}


export function sendEmail(token, { to, subject,  message}) {
    return fetch(API_NEWSLETTER_SEND ,{
        method: "POST",
        headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
        body:JSON.stringify({ to, subject,  message})
    })
}


import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"; 
import {connect} from 'react-redux'
import Authentification from './containers/Authentification/index'
import Places from './containers/Places/index'
import Mice from './containers/Mice/index'
import Deals from './containers/Deals/index'
import DealsEdit from './containers/Places/EditPlace'
import PlacesEdit from './containers/Places/EditPlace'
import Tags from './containers/Tags/index'
import Articles from './containers/Articles/index'
import Tours from './containers/Tours/index'
import Cities from './containers/Cities/index'
import Stories from './containers/Stories/index'
import Bi from './containers/Bi/index'
import Hiking from './containers/Circuits'
import Newsletter from './containers/Newsletter'
import InstaWall from './containers/InstaWall'
import {logout} from './actions/auth'


import './fonts/fonts.css'
import './App.css';


function App({ token }) {

  return (
    <Router>
      <Switch>
      <Route exact path="/deals/:id" render={(props) =>
        token && token.length > 10 ? <DealsEdit route={props} /> : <Redirect to="/login" />
      }/>
      <Route exact path="/deals">
        {token && token.length > 10 ? <Deals /> : <Redirect to="/login" />}
      </Route>
        <Route exact path="/places/:id" render={(props) =>
          token && token.length > 10 ? <PlacesEdit route={props} /> : <Redirect to="/login" />
        }/>
        <Route exact path="/places">
          {token && token.length > 10 ? <Places /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/tags">
          {token && token.length > 10 ? <Tags /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/articles">
        {token && token.length > 10 ? <Articles /> : <Redirect to="/login" />}
      </Route>

        <Route exact path="/cities">
        {token && token.length > 10 ? <Cities /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/stories">
        {token && token.length > 10 ? <Stories /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/mice">
        {token && token.length > 10 ? <Mice /> : <Redirect to="/login" />}
        </Route>
        
        <Route exact path="/tours">
          {token && token.length > 10 ? <Tours /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/hiking">
          {token && token.length > 10 ? <Hiking /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/bi">
        {token && token.length > 10 ? <Bi /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/newsletter">
        {token && token.length > 10 ? <Newsletter /> : <Redirect to="/login" />}
        </Route>
        
        <Route exact path="/instagram">
        {token && token.length > 10 ? <InstaWall /> : <Redirect to="/login" />}
        </Route>
        
        <Route exact path="/login">
          {token && token.length > 10 ? (
            <Redirect to="/places" />
          ) : (
            <Authentification />
          )}
        </Route>
        <Route exact path="/">
          {token && token.length > 10 ? <Places /> : <Redirect to="/login" />}
        </Route>
      </Switch>
    </Router>
  );
}

const mapStateToProps = state => ({
  ...state.Auth
})
 
const mapDispatchToProps = dispatch => ({
logout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(App);


import { API_CITY, JSON_HEADERS } from '../constants'
import { message } from 'antd';

export const SET_CITIES = 'SET_CITIES'
export const ADD_CITY = 'ADD_CITY'
export const UPDATE_CITY = 'UPDATE_CITY'

// setAuthToken
function setCities(cities) {
    return {
        type: SET_CITIES,
        payload: cities
    }
}

function addCity(city) {
    return {
        type: ADD_CITY,
        payload: city
    }
}


// get Cities from the server
export function fetchSetCities(token) {
    return function (dispatch) {
        return fetchApiGetCities(token)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setCities(response.data))
                    } else {
                        message.error('Something bad happens. Code: CE01')
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: CE02'))
    }
    
}

function fetchApiGetCities(token) {
    return fetch(API_CITY,{
        method: "GET",
        headers: {...JSON_HEADERS, Authorization: "Bearer "+ token},
    })
}


// Update a place
function fetchApiPutCity(token, data) {
    if (token && data && data._id) {
        return fetch(API_CITY+'/'+data._id,{
            method: "PUT",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchUpdateACity(token, data) {
    return function (dispatch) {
        return fetchApiPutCity(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(fetchSetCities(token))
                        message.success('This place has been updated successfuly')
                    } else {
                        message.error('Something bad happens. Code: CU01')
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: CU02'))
    }
}

// Add a new place

function fetchApiPostCity(token, data) {
    if (token && data) {
        return fetch(API_CITY,{
            method: "POST",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchAddACity(token, data) {
    return function (dispatch) {
        return fetchApiPostCity(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(addCity(response.data))
                        message.success('This city has been added successfuly')
                    } else {
                        message.error('Something bad happens. Code: CA01')
                    }
                })
            .catch(e => {
                console.log(e)
                message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: CA02')
            })
    }
}

// Remove a Tag
function fetchApiDeletePlace(token, tagId) {
    if (token && tagId) {
        return fetch(API_CITY+'/'+tagId,{
            method: "DELETE",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchRemoveACity(token, tagId) {
    return function (dispatch) {
        return fetchApiDeletePlace(token, tagId)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(fetchSetCities(token))
                        message.success('This city has been removed successfuly')
                    } else {
                        message.error('Something bad happens. Code: CD01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: CD02'))
    }
}

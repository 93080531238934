import React from 'react'
import { connect } from 'react-redux'
import {fetchRemoveAInstawallImage} from '../../actions/instawall'
import { List, Row, Col, Popconfirm, Icon } from 'antd';
import { getSafe } from '../../constants'
import Spacer from '../../components/Spacer'
import Layout from '../../components/Layout'
import AddNewInstaWall from './AddNew'

import './index.css'


function Instawall({ instawall, token, fetchRemoveAInstawallImage }) {
    return (
      <Layout>
        <Row gutter={50}>
          <Col md={6}>
            <List
              bordered
              dataSource={instawall}
              renderItem={(item) => (
                <List.Item>
                  {<img alt="" src={getSafe(()=>item.media[0]['url'])} width='50%' height='40px' style={{objectFit:'cover'}}/> }
                  <Popconfirm
                    title="Are you sure delete this instawall?"
                    onConfirm={() => {
                      fetchRemoveAInstawallImage(token, item._id);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                   
                    <div
                      style={{
                        position: "absolute",
                        right: "20px",
                        cursor: "pointer",
                      }}
                    >
                     
                      <Icon type="delete" style={{ fontSize: "20px" }} />
                    </div>
                  </Popconfirm>
                </List.Item>
              )}
            />
          </Col>
          <Col md={12}>
            <AddNewInstaWall />
          </Col>
        </Row>
        <Spacer top />
      </Layout>
    );
}

const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Instawall
  })
  
const mapDispatchToProps = dispatch => ({
  fetchRemoveAInstawallImage: (token, id)=>dispatch(fetchRemoveAInstawallImage(token, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Instawall)
  
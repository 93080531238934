import React from 'react'
import Layout from '../../components/Layout'

export default function Bi() {
    return (
      <Layout>
        <h1>
          Soon...
        </h1>
          
      </Layout>
    );
}

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Input, Row, Col, Select, Icon, Upload, Avatar, Popconfirm, TimePicker, Popover, Switch, DatePicker, Button, message } from 'antd'
import {generate} from 'shortid'
import { fetchAddAPlace } from '../../actions/places'
import { API_UPLOAD, PLACES_DATA_SKELETON} from '../../constants'

const { RangePicker } = DatePicker;
const { Option } = Select 
const { TextArea } = Input
const { Dragger } = Upload;

const dateFormat = 'YYYY/MM/DD';

const weekDays = [
  {
    id: 0,
    name:'monday'
  },
  {
    id: 1,
    name:'tuesday'
  },
  {
    id: 2,
    name:'wednesday'
  },
  {
    id: 3,
    name:'thursday'
  },
  {
    id: 4,
    name:'friday'
  },
  {
    id: 5,
    name:'saturday'
  },
  {
    id: 6,
    name:'sunday'
  }
]



const MarginTop = () => (<div style={{ marginTop: '20px' }}></div>)

function EditPlace({ tags, fetchAddAPlace, token, cities }) {
  

  // Input handlers
  const [id, setId] = useState(generate())
  const [contact, setContact] = useState(PLACES_DATA_SKELETON[0].contact)
  const [status, setStatus] = useState(PLACES_DATA_SKELETON[0].status)
  const [location, setLocation] = useState(PLACES_DATA_SKELETON[0].location)
  const [description, setDescription] = useState(PLACES_DATA_SKELETON[0].description)
  const [title, setTitle] = useState(PLACES_DATA_SKELETON[0].title)
  const [subtitle, setSubtitle] = useState(PLACES_DATA_SKELETON[0].subtitle)
  const [openingHours, setOpeningHours] = useState(PLACES_DATA_SKELETON[0].openingHours)
  const [misc, setMisc] = useState(PLACES_DATA_SKELETON[0].misc)
  const [formTags, setformTags] = useState( PLACES_DATA_SKELETON[0].tags)
  const [media, setMedia] = useState([])



  const handleTags = (tagsId =[]) => {
    setformTags(tags.filter(t=>tagsId.indexOf(t.id)>-1))
  }

  const handleRemoveMedia = (mediaUrl) => {
    setMedia(media.filter(m=>m.url !== mediaUrl))
  }

  const handleOpeningHours = (hours, week, is24, opensOrCloses) => {
    if ([0,1,2,3,4,5,6].indexOf(week) === -1) {
      return
    }

    let newOpeningHours = { ...openingHours }

    if (hours && opensOrCloses) {
      newOpeningHours[week][opensOrCloses] = hours
    }

    if (is24) {
      newOpeningHours[week]['is24'] = is24
    }

    setOpeningHours(newOpeningHours)
  }

  // media uploads
  const dropSettings = {
    name: 'file',
    multiple: false,
    action: API_UPLOAD,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        //console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setMedia([...media, info.file.response ])
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  

  const updatedObject = {
    id, contact, status, location, description, title, subtitle, media, tags: formTags, openingHours, misc, isDeal:true
  }


  
  return (
    <div>
     
      
        <div>
      <div style={{position:'absolute', bottom:0, zIndex:9999999, width:'100%', right:0 }}>
      
            <Button type="primary" style={{ borderRadius: 0 }} block size={'large'} onClick={()=>fetchAddAPlace(token, updatedObject)}>Add a new</Button>
      </div>
      
      <div className="places">
        {/**
    Place ID,
    Status
    */}
        <Row gutter={16}>
          <h2>
            <Icon type="edit" /> Misc
          </h2>
            <Col md={2}>
            <Popover content={<p>Auto-generated Place ID. You can modify it but it needs to be uniq</p>} trigger="hover">
              <Input
                defaultValue={id}
              placeholder={"Place ID"}
              onChange={event => setId(event.target.value)}
                />
                </Popover>
          </Col>
          <Col md={4}>
            <Select
              defaultValue={"draft"}
              style={{ width: 120 }}
              onSelect={event => setStatus(event)}
            >
              <Option value="draft">Draft</Option>
              <Option value="published">Published</Option>
            </Select>
          </Col>
        </Row>

        {/**
       Title,
        En/FR/ES/AR/ZH
      */}
        <MarginTop />
        <Row gutter={16}>
          <h2>
            <Icon type="edit" /> Title
          </h2>
          <Col md={4}>
            <Input
              placeholder={"Title FR"}
              onChange={event => setTitle({ ...title, fr: event.target.value })}
            />
            <label className={'required'}>Français ** Required **</label>
          </Col>
          <Col md={4}>
            <Input
              placeholder={"Title EN"}
              onChange={event => setTitle({ ...title, en: event.target.value })}
            />
            <label>English</label>
          </Col>
          <Col md={4}>
            <Input
              placeholder={"Title ES"}
              onChange={event => setTitle({ ...title, es: event.target.value })}
            />
            <label>española</label>
          </Col>
          <Col md={4}>
            <Input
              placeholder={"Title AR"}
              onChange={event => setTitle({ ...title, ar: event.target.value })}
            />
            <label>عربى</label>
          </Col>
          <Col md={4}>
            <Input
              placeholder={"Title ZH"}
              onChange={event => setTitle({ ...title, zh: event.target.value })}
            />
            <label>Zh</label>
          </Col>
        </Row>

        <MarginTop />

        {/**
         Subtitle,
          En/FR/ES/AR/ZH
        */}

        <Row gutter={16}>
          <h2>
            <Icon type="edit" /> SubTitle
          </h2>
          <Col md={4}>
            <Input
              placeholder={"SubTitle FR"}
              onChange={event =>
                setSubtitle({ ...subtitle, fr: event.target.value })
              }
            />
            <label>Français</label>
          </Col>
          <Col md={4}>
            <Input
              placeholder={"SubTitle EN"}
              onChange={event =>
                setSubtitle({ ...subtitle, en: event.target.value })
              }
            />
            <label>English</label>
          </Col>
          <Col md={4}>
            <Input
              placeholder={"SubTitle ES"}
              onChange={event =>
                setSubtitle({ ...subtitle, es: event.target.value })
              }
            />
            <label>española</label>
          </Col>
          <Col md={4}>
            <Input
              placeholder={"SubTitle AR"}
              onChange={event =>
                setSubtitle({ ...subtitle, ar: event.target.value })
              }
            />
            <label>عربى</label>
          </Col>

          <Col md={4}>
            <Input
              placeholder={"SubTitle ZH"}
              onChange={event =>
                setSubtitle({ ...subtitle, zh: event.target.value })
              }
            />
            <label>Zh</label>
          </Col>
        </Row>
        <MarginTop />

        {/**
         description,
          En/FR/ES/AR/ZH
        */}

        <Row gutter={16}>
          <h2>
            <Icon type="edit" /> Description
          </h2>
          <Col md={4}>
            <TextArea
              rows={20}
              placeholder={"Description FR"}
              onChange={event =>
                setDescription({ ...description, fr: event.target.value })
              }
            />
          </Col>
          <Col md={4}>
            <TextArea
              rows={20}
              placeholder={"Description EN"}
              onChange={event =>
                setDescription({ ...description, en: event.target.value })
              }
            />
          </Col>
          <Col md={4}>
            <TextArea
              rows={20}
              placeholder={"Description ES"}
              onChange={event =>
                setDescription({ ...description, es: event.target.value })
              }
            />
          </Col>
          <Col md={4}>
            <TextArea
              rows={20}
              placeholder={"Description AR"}
              onChange={event =>
                setDescription({ ...description, ar: event.target.value })
              }
            />
          </Col>

          <Col md={4}>
            <TextArea
              rows={20}
              placeholder={"Description ZH"}
              onChange={event =>
                setDescription({ ...description, zh: event.target.value })
              }
            />
          </Col>
        </Row>

        {/**
         Contact,
          Email/Phone/url/AR/ZH/address{street_address, postal_code, city}
          Location{coordinates[longitude, latitude]}
        */}

        <MarginTop />
        <Row gutter={16} style={{ paddingBottom: "10px" }}>
          <h2>
            <Icon type="edit" /> Contact
          </h2>
          <Col md={2}>
          <Input
            placeholder={"Nom responsable"}
            onChange={event =>
              setContact({ ...contact, name: event.target.value })
            }
          />
        </Col>
          <Col md={2}>
            <Input
              placeholder={"Email"}
              onChange={event =>
                setContact({ ...contact, email: event.target.value })
              }
            />
          </Col>
          <Col md={2}>
            <Input
              placeholder={"Phone"}
              onChange={event =>
                setContact({ ...contact, phone: event.target.value })
              }
            />
          </Col>
          <Col md={3}>
            <Input
              placeholder={"Url"}
              onChange={event =>
                setContact({ ...contact, url: event.target.value })
              }
            />
          </Col>
          <Col md={4}>
            <Input
              placeholder={"Street Address"}
              onChange={event =>
                setContact({
                  ...contact,
                  address: {
                    ...contact.address,
                    street_address: event.target.value
                  }
                })
              }
            />
          </Col>

          <Col md={2}>
            <Input
              placeholder={"Postal Code"}
              onChange={event =>
                setContact({
                  ...contact,
                  address: {
                    ...contact.address,
                    postal_code: event.target.value
                  }
                })
              }
            />
          </Col>

          <Col md={3}>
            <Select
              style={{ width: 120 }}
              onSelect={event =>
                setContact({
                  ...contact,
                  address: { ...contact.address, city: event }
                })
              }
            >
              {cities.map((c, k) => (
                <Option value={c.slug} key={c.slug + c.id}>
                  ->{c.title['fr']}
                </Option>
              ))}
            </Select>
          </Col>

          <Col md={4}>
            <Input
              type="number"
              step="0.0000000000001"
              placeholder={"Longitude"}
              onChange={event =>
                setLocation({
                  ...location,
                  coordinates: [
                    parseFloat(event.target.value),
                    (location && location.coordinates && location.coordinates[1]) ? location.coordinates[1] : null
                  ]
                })
              }
              />
             
              <label className={'required'}>Longitude ** Required **</label>
          </Col>

          <Col md={4}>
            <Input
              type="number"
              step="0.0000000000001"
              placeholder={"Latitude"}
              onChange={event =>
                setLocation({
                  ...location,
                  coordinates: [
                    (location && location.coordinates && location.coordinates[0]) ? location.coordinates[0] : null,
                    parseFloat(event.target.value)
                  ]
                })
              }
              />
              <label className={'required'}>Latitude ** Required **</label>
          </Col>
        </Row>
        <MarginTop />

        {/**Tags */}
        <Row>
          <h2>
            <Icon type="edit" /> Tags
          </h2>
          <Col md={24}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
              onChange={t => handleTags(t)}
            >
              {tags &&
                tags[0] &&
                tags.map((t, k) => (
                  <Option key={t.name.fr + "-" + Date.now()} value={t.id}>
                    {t.name.fr}
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>
        <MarginTop />

        <Row>
          <Dragger {...dropSettings}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Dragger>

          <MarginTop />
          {(media && media[0] && media[0].url)&&
            media.map((m, k) => (
              <Popconfirm
                placement="topLeft"
                title={"Delete this image?"}
                onConfirm={() => handleRemoveMedia(m.url)}
                okText="Yes"
                cancelText="No"
                key={m.url}
              >
                <Avatar
                  size={64}
                  src={m.url}
                  style={{ cursor: "pointer", margin: "20px" }}
                />
              </Popconfirm>
            ))}
        </Row>
        <MarginTop />

        <Row style={{ textAlign: "center" }}>
          <h2 style={{ textAlign: "left" }}>
            <Icon type="edit" />
            Opening Hours
          </h2>
          {weekDays.map(w => (
            <Col md={3} key={w.name + 999}>
              <p style={{ textAlign: "center", fontWeight: 900 }}>{w.name}</p>
              <Col md={24}>
                <TimePicker
                  minuteStep={30}
                  secondStep={60}
                  format={"HH:mm"}
                  onChange={(time, timeString) => {
                    handleOpeningHours(timeString, w.id, false, "opens");
                  }}
                />
              </Col>
              <Col md={24}>
                <TimePicker
                  minuteStep={30}
                  secondStep={60}
                  format={"HH:mm"}
                  onChange={(time, timeString) => {
                    handleOpeningHours(timeString, w.id, false, "closes");
                  }}
                />
              </Col>
              <Col md={24}>
                <Switch
                  checkedChildren="Is 24h"
                  unCheckedChildren="Not 24h"
                  onChange={e => {
                    handleOpeningHours(null, w.id, e, "closes", null);
                  }}
                />
              </Col>
            </Col>
          ))}
        </Row>
        <MarginTop />

        <Row gutter={16}>
          <h2 style={{ textAlign: "left" }}>
            <Icon type="edit" />
            Other
          </h2>
          <Col md={4}>
          <Popover content={<p>Minimum visit duration</p>} trigger="hover">
            <Input placeholder="Minimum visit duration" type={"number"}
           
                  onChange={(e)=>setMisc({...misc, minVisitDuration: e.target.value})}
              />
              </Popover>
            </Col>
            <Col md={4}>
            <Popover content={<p>Condition de l'offre</p>} trigger="hover">
              <Input placeholder="Condition de l'offre" onChange={(e)=>setMisc({...misc, offerCondition: e.target.value})}
                />
                </Popover>
            </Col>
          <Col md={4}>
          <Popover content={<p>Cost in Dirhams</p>} trigger="hover">
            <Input placeholder="Cost (Dhs)" type={"number"}
           
            onChange={(e)=>setMisc({...misc, cost: e.target.value})}
              />
              </Popover>
            </Col>
            <Col md={4}>
          <Popover content={<p>Special Price (cost with discount)</p>} trigger="hover">
            <Input placeholder="Special price (Dhs)" type={"number"}
           
            onChange={(e)=>setMisc({...misc, specialPrice: e.target.value})}
              />
              </Popover>
          </Col>
          <Col md={4}>
          <Popover content={<p>Hotel Rating (1 to 5)</p>} trigger="hover">
            <Input placeholder="Hotel Rating" type={"number"}
            
            onChange={(e)=>setMisc({...misc, HotelRating: e.target.value})}
              />
              </Popover>
          </Col>
          <Col md={4}>
          <Popover content={<p>Bias</p>} trigger="hover">
            <Input placeholder="Bias" type={"number"}
           
            onChange={(e)=>setMisc({...misc, bias: e.target.value})}
              />
              </Popover>
          </Col>
          <Col md={4}>
          <Popover content={<p>Price range. Example: $$</p>} trigger="hover">

            <Input placeholder="Price range"
            
            onChange={(e)=>setMisc({...misc, priceRange: e.target.value})}
              />
              </Popover>
          </Col>

          <Col md={24} style={{ paddingTop: "20px" }}>
            <RangePicker
              onChange={e => {
                setMisc({
                  ...misc,
                  EventStartDate: e[0].format(dateFormat),
                  EventEndDate:  e[1].format(dateFormat)
                })
               
              }}
              format={dateFormat}
            />
          </Col>

          <Col md={3} style={{ paddingTop: "20px" }}>
            <Switch checkedChildren="Wifi" unCheckedChildren="Wifi"
           
            onChange={(e)=>setMisc({...misc, wifi: e})}
            />
          </Col>
          <Col md={3} style={{ paddingTop: "20px" }}>
            <Switch
           
              checkedChildren="Alcool" unCheckedChildren="Alcool"
            onChange={(e)=>setMisc({...misc, alcool: e})}
            />
          </Col>
          <Col md={3} style={{ paddingTop: "20px" }}>
            <Switch
              checkedChildren="WheelChair"
              unCheckedChildren="WheelChair"
              onChange={(e)=>setMisc({...misc, suitableForDisabled: e})}
            />
          </Col>
        </Row>
          </div>
          </div>
      
    </div>
  );
}


const mapStateToProps = state => ({
    ...state.Auth,
  ...state.Places,
  ...state.Tags,
  ...state.Cities
  })
  
  const mapDispatchToProps = dispatch => ({
    fetchAddAPlace: (token, data) => dispatch(fetchAddAPlace(token, data))
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(EditPlace);
  
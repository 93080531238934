import { SET_CITIES, ADD_CITY  } from '../actions/city'

const initState = {
    cities: []
}
  
export default function tagsReducer (state = initState, action) {
    switch (action.type) {
      case SET_CITIES:
            return {
                cities: action.payload
        }
      case ADD_CITY:
        return {
            cities: [...state.cities, action.payload]
        }
    
      default:
        return state
    }
  }
  

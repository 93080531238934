import { API_TAGS, JSON_HEADERS } from '../constants'
import { message } from 'antd';

export const SET_TAGS = 'SET_TAGS'
export const UPDATE_TAG = 'UPDATE_TAG'
export const ADD_TAG = 'ADD_TAG'
export const DELETE_TAG = 'DELETE_TAG'

// setAuthToken
function setTags(tags) {
    return {
        type: SET_TAGS,
        payload: tags
    }
}

function addTag(tag) {
    return {
        type: ADD_TAG,
        payload: tag
    }
}



// get tags from the server
export function fetchSetTags(token) {
    return function (dispatch) {
        return fetchApiGetTags(token)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setTags(response.data))
                    } else {
                        message.error('Something bad happens. Code: TE01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: TE02'))
    }
    
}

function fetchApiGetTags(token) {
    return fetch(API_TAGS ,{
        method: "GET",
        headers: {...JSON_HEADERS, Authorization: "Bearer "+ token},
    })
}


// Update a tag

function fetchApiPutTag(token, data) {
    if (token && data && data._id) {
        return fetch(API_TAGS+'/'+data._id,{
            method: "PUT",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchUpdateATag(token, data) {
    return function (dispatch) {
        return fetchApiPutTag(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(fetchSetTags(token))
                        message.success('Tag is successfuly updated');
                    } else {
                        message.error('Something bad happens. Code: TU01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: TU02'))
    }
}

// Add a new tag

function fetchApiPostTag(token, data) {
    if (token && data) {
        return fetch(API_TAGS,{
            method: "POST",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchAddATag(token, data) {
    return function (dispatch) {
        return fetchApiPostTag(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(addTag(response.data))
                        message.success('This tag has been added successfuly')
                    } else {
                        message.error('Something bad happens. Code: TA01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: TA02'))
    }
}


// Remove a Tag
function fetchApiDeleteTag(token, tagId) {
    if (token && tagId) {
        return fetch(API_TAGS+'/'+tagId,{
            method: "DELETE",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchRemoveATag(token, tagId) {
    return function (dispatch) {
        return fetchApiDeleteTag(token, tagId)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(fetchSetTags(token))
                        message.success('This tag has been removed successfuly')
                    } else {
                        message.error('Something bad happens. Code: TD01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: TD02'))
    }
}

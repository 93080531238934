import { SET_CIRCUITS, ADD_CIRCUIT  } from '../actions/circuits'

const initState = {
    circuits: []
}
  
export default function tagsReducer (state = initState, action) {
    switch (action.type) {
      case SET_CIRCUITS:
            return {
                circuits: action.payload
        }
      
      case ADD_CIRCUIT:
        return {
            circuits: [...state.circuits, action.payload]
        }
    
      default:
        return state
    }
  }
  

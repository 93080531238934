import React, { useState } from 'react'
import {connect} from 'react-redux'
import { Input, Row, Col, Button, Icon, Select, message } from 'antd'
import { fetchUpdateATour } from '../../actions/tours'

const { Option } = Select;
const { TextArea } = Input



const prepareDataBeforesend = (tourDetails) => {

   
  if (!tourDetails || !tourDetails.title || !tourDetails.title.fr) {
      message.error('You need at least add French tour Name')
      return
  }
  
  if (!tourDetails || !tourDetails.places || !tourDetails.places[1]) {
      message.error('You need at least to add 2 points')
      return
  }

  return tourDetails
    
}

function AddNewTour({ fetchUpdateATour, token, places, data }) {

  const defaultState = {
    id: data.id,
    _id: data._id,
    title: data.title,
    description: data.description,
    places: data.places
  }

  const [tourDetails, setTourDetails] = useState(defaultState)
  
  return (
      <div>
        <Row gutter={16}>
          <h2>
            <Icon type="edit" /> Title
          </h2>
          <Col md={24}>
            
              <Input
            placeholder={"Français"}
            defaultValue={tourDetails.title['fr']}
              onChange={event => {
                let newTourDetails = { ...tourDetails }
                newTourDetails.title['fr'] = event.target.value
                setTourDetails(newTourDetails)
              }}
              />
          
          </Col>
          <Col md={24}>
            
              <Input
            placeholder={"English"}
            defaultValue={tourDetails.title['en']}
                onChange={event => {
                  let newTourDetails = { ...tourDetails }
                  newTourDetails.title['en'] = event.target.value
                  setTourDetails(newTourDetails)
                }}
              />
          
                </Col>
                <Col md={24}>
            
              <Input
            placeholder={"española"}
            defaultValue={tourDetails.title['es']}
                onChange={event => {
                  let newTourDetails = { ...tourDetails }
                  newTourDetails.title['es'] = event.target.value
                  setTourDetails(newTourDetails)
                }}
              />
          
                </Col>
                <Col md={24}>
            
          <Input
              defaultValue={tourDetails.title['ar']}
                  placeholder={"عربى"}
                  onChange={event => {
                    let newTourDetails = { ...tourDetails }
                    newTourDetails.title['ar'] = event.target.value
                    setTourDetails(newTourDetails)
                  }}
                />
            
                </Col>
                <Col md={24}>
            
          <Input
              defaultValue={tourDetails.title['zh']}
                  placeholder={"Chinese"}
                  onChange={event => {
                    let newTourDetails = { ...tourDetails }
                    newTourDetails.title['zh'] = event.target.value
                    setTourDetails(newTourDetails)
                  }}
                />
            
          </Col>
          
         
                
        </Row>




        <Row gutter={16}>
        <h2>
          <Icon type="edit" /> Description
        </h2>
        <Col md={24}>
          
          <TextArea
              defaultValue={tourDetails.description['fr']}
              placeholder={"Français"}
            onChange={event => {
              let newTourDetails = { ...tourDetails }
              newTourDetails.description['fr'] = event.target.value
              setTourDetails(newTourDetails)
            }}
            />
        
        </Col>
        <Col md={24}>
          
          <TextArea
               defaultValue={tourDetails.description['en']}
              placeholder={"English"}
              onChange={event => {
                let newTourDetails = { ...tourDetails }
                newTourDetails.description['en'] = event.target.value
                setTourDetails(newTourDetails)
              }}
            />
        
              </Col>
              <Col md={24}>
          
          <TextArea
               defaultValue={tourDetails.description['es']}
              placeholder={"española"}
              onChange={event => {
                let newTourDetails = { ...tourDetails }
                newTourDetails.description['es'] = event.target.value
                setTourDetails(newTourDetails)
              }}
            />
        
              </Col>
              <Col md={24}>
          
          <TextArea
               defaultValue={tourDetails.description['ar']}
                placeholder={"عربى"}
                onChange={event => {
                  let newTourDetails = { ...tourDetails }
                  newTourDetails.description['ar'] = event.target.value
                  setTourDetails(newTourDetails)
                }}
              />
          
              </Col>
              <Col md={24}>
          
          <TextArea
               defaultValue={tourDetails.description['zh']}
                placeholder={"Chinese"}
                onChange={event => {
                  let newTourDetails = { ...tourDetails }
                  newTourDetails.description['zh'] = event.target.value
                  setTourDetails(newTourDetails)
                }}
              />
          
        </Col>
        
       
              
      </Row>



       
        <Row>
        <Col md={24}>
        <h2>
        <Icon type="edit" /> Places
      </h2>
        <Select
          mode="multiple"
            style={{ width: '100%' }}
            defaultValue={Array.isArray(tourDetails.places) ? tourDetails.places.map(p=>p._id) : []}
            placeholder="Please select"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(data) => {
              let newTourDetails = { ...tourDetails }
              newTourDetails.places = [...data]
              setTourDetails(newTourDetails)
          }}
          >
          
            {
              places.map((p, k) => {
                return <Option key={p.title.fr + k} value={p._id}>{p.title.fr}</Option>
              })
            }
            
        </Select>
        </Col>
        
        </Row>
        <Button type="primary" block size={'large'} onClick={() => {
          //console.log((prepareDataBeforesend(tourDetails)))
          fetchUpdateATour(token, prepareDataBeforesend(tourDetails))
        }}>Update</Button>
      </div>
    );
}


const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
    ...state.Tags
  })
  
const mapDispatchToProps = dispatch => ({
  fetchUpdateATour: (token, data) => dispatch(fetchUpdateATour(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNewTour)
  
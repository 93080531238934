import React, { useState } from 'react'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill'
import { Input, Row, Col, Button, Icon, Select, Upload, message } from 'antd'
import { API_UPLOAD} from '../../constants'
import { fetchUpdateAArticle } from '../../actions/articles'
import 'react-quill/dist/quill.snow.css';

const { Option } = Select 
const { Dragger } = Upload;

function EditArticle({ fetchUpdateAArticle, token, data, cities }) {
  const tags= ['url', 'news', 'events', 'stats', 'publications', 'documentations', 'com_press', 'rev_press']
  const [article, setArticle] = useState({
    title: data.title,
    content: data.content,
    featuredImage: data.featuredImage,
    _id: data._id,
    tags: data.tags,
    status: data.status,
    city: data.city
  });
    // media uploads
    const dropSettingsMedia = {
      name: 'file',
      multiple: false,
      action: API_UPLOAD,
      onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
          //console.log(info.file, info.fileList);
        }
        if (status === 'done') {
          setArticle({...article, featuredImage: info.file.response.url})
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
  };

   // media uploads
   const dropSettingsAttachment = {
    name: 'file',
    multiple: false,
    action: API_UPLOAD,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        //console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setArticle({...article, attachment:  info.file.response.url })
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
};

  
  
    return (
      <div>
        <Row>
          <h2>
            <Icon type="edit" /> Edit
          </h2>
          <Col md={24}>
        
          <Select
         
              style={{ width: 120 }}
              defaultValue={article.status}
          onSelect={event => setArticle({...article, status:event})}
        >
          <Option value="draft">Draft</Option>
          <Option value="published">Published</Option>
            </Select>
            
            
          
                </Col>
          <Col md={24}>
            <Input
            defaultValue={article.title}
          placeholder={"Title"}
          onChange={event => setArticle({...article, title:event.target.value})}
        />
            
         
          </Col>

          <Col md={24}>
            <Select
            mode="multiple"
              style={{ width: "100%" }}
              defaultValue={data.tags}
            placeholder="Tags"
            onChange={t =>  setArticle({...article, tags:t})}
          >
            {tags &&
              tags[0] &&
              tags.map((t, k) => (
                <Option key={t} value={t}>
                  {t}
                </Option>
              ))}
        </Select>
        
           
        
          </Col>

          <Col md={24}>
            <Select
            style={{ width: "100%" }}
              placeholder="City"
              defaultValue={data.city}
            onChange={t =>  setArticle({...article, city:t})}
          >
            {cities &&
              cities[0] &&
              cities.map((t, k) => (
                <Option key={t.slug} value={t.slug}>
                  {t.slug}
                </Option>
              ))}
        </Select>
        
           
        
      </Col>
          
          
          <Col md={24}>
        
            <ReactQuill value={article.content}
            onChange={(content)=>setArticle({...article, content: content})} />
        
          </Col>
          <Col md={24}>
          <Dragger {...dropSettingsMedia}>
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Add a featured image
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload.
          </p>
            </Dragger>
            
            <Dragger {...dropSettingsAttachment}>
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">
                Add an Attachment
              </p>
              <p className="ant-upload-hint">
                Support for a single upload.
              </p>
            </Dragger>
        </Col>
                
            </Row>
        <Button type="primary" block size={'large'} onClick={() => {
          fetchUpdateAArticle(token,  article)
          //console.log(article)
        }}>Update</Button>
      </div>
    );
}


const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
  ...state.Articles,
    ...state.Cities
  })
  
const mapDispatchToProps = dispatch => ({
  fetchUpdateAArticle: (token, data) => dispatch(fetchUpdateAArticle(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditArticle)
  
import { API_TOURS, JSON_HEADERS } from "../constants";
import { message } from "antd";

export const SET_TOURS = "SET_TOURS";
export const UPDATE_TOUR = "UPDATE_TOUR";
export const ADD_TOUR = "ADD_TOUR";
export const DELETE_TOUR = "DELETE_TOUR";

// setAuthToken
function setTours(tours) {
  return {
    type: SET_TOURS,
    payload: tours,
  };
}

function addTour(tour) {
  return {
    type: ADD_TOUR,
    payload: tour,
  };
}

// get tours from the server
export function fetchSetTours(token) {
  return function (dispatch) {
    return fetchApiGetTours(token)
      .then(function (res) {
        return res.json();
      })
      .then(function (response) {
        if (response && response.data) {
          dispatch(setTours(response.data));
        } else {
          message.error("Something bad happens. Code: TE01");
        }
      })
      .catch((e) => message.error("Something bad happens. Code: TE02"));
  };
}

function fetchApiGetTours(token) {
  return fetch(API_TOURS, {
    method: "GET",
    headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
  });
}

// Update a tour

function fetchApiPutTour(token, data) {
    console.log(data)
  if (token && data && data._id) {
    return fetch(API_TOURS + "/" + data._id, {
      method: "PUT",
      headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
      body: JSON.stringify(data),
    });
  } else {
    return Promise.reject(new Error("Missing Data"));
  }
}

export function fetchUpdateATour(token, data) {
  return function (dispatch) {
    return fetchApiPutTour(token, data)
      .then(function (res) {
        return res.json();
      })
      .then(function (response) {
        if (response && response.data && response.data._id) {
          message.success("Tour is successfuly updated");
        } else {
          message.error("Something bad happens. Code: TU01");
        }
      })
        .catch((e) => {
            message.error("Something bad happens. Code: TU02")
        });
  };
}

// Add a new tour

function fetchApiPostTour(token, data) {
  if (token && data) {
    return fetch(API_TOURS, {
      method: "POST",
      headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
      body: JSON.stringify(data),
    });
  } else {
    return Promise.reject(new Error("Missing Data"));
  }
}

export function fetchAddATour(token, data) {
  return function (dispatch) {
    return fetchApiPostTour(token, data)
      .then(function (res) {
        return res.json();
      })
      .then(function (response) {
        if (response && response.data && response.status === "success") {
          dispatch(addTour(response.data));
          message.success("This tour has been added successfuly");
        } else {
          message.error("Something bad happens. Code: TA01");
        }
      })
      .catch((e) => message.error("Something bad happens. Code: TA02"));
  };
}

// Remove a Tour
function fetchApiDeleteTour(token, tourId) {
  if (token && tourId) {
    return fetch(API_TOURS + "/" + tourId, {
      method: "DELETE",
      headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
    });
  } else {
    return Promise.reject(new Error("Missing Data"));
  }
}

export function fetchRemoveATour(token, tourId) {
  return function (dispatch) {
    return fetchApiDeleteTour(token, tourId)
      .then(function (res) {
        return res.json();
      })
      .then(function (response) {
        if (response && response.data && response.status === "success") {
          dispatch(fetchSetTours(token));
          message.success("This tour has been removed successfuly");
        } else {
          message.error("Something bad happens. Code: TD01");
        }
      })
      .catch((e) => message.error("Something bad happens. Code: TD02"));
  };
}

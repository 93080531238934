import {SET_AUTH_TOKEN, UNSET_AUTH_TOKEN} from '../actions/auth'

const initState = {
    token: null
}
  
export default function poiReducer (state = initState, action) {
    switch (action.type) {
      case SET_AUTH_TOKEN:
            return {
            token: action.payload
        }
        case UNSET_AUTH_TOKEN:
          return {
          token: ''
      }
      default:
        return state
    }
  }
  

import React, {useState, useEffect} from 'react'

import { connect } from 'react-redux';
import { login, logout } from '../../actions/auth'
import { Form, Icon, Input, Button } from 'antd';
//import history from '../history'
import SITE_LOGO from '../../logo.svg'
import './Authentification.css'

function Authentification({form, token, login, logout}) {
    //history.push('/collection/')
    
    const { getFieldDecorator } = form;
    const [formValues, setFormValues] = useState(null)

    useEffect(() => {
        if (formValues) {
            login(formValues)
        }
        
    }, [formValues, login])
  
  
    
    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                setFormValues(values)
                //console.log(values);
          }
        })
    };

    return (
      <div>
        <div className="center-auth">
          <img src={SITE_LOGO} className="auth-logo" alt="logo" />

          <div className="auth-container">
            {token && (
              <div style={{ display: "block" }}>
                <Button type="danger" onClick={() => logout()}>
                  Logout
                </Button>
              </div>
            )}

            {!token && (
              <Form onSubmit={handleSubmit} className="login-form">
                <Form.Item>
                  {getFieldDecorator("username", {
                    rules: [
                      { required: true, message: "Please input your username!" }
                    ]
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="Username"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("password", {
                    rules: [
                      { required: true, message: "Please input your Password!" }
                    ]
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      type="password"
                      placeholder="Password"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            )}
          </div>
        </div>
      </div>
    );
}

const AuthentificationForm = Form.create({ name: 'normal_login' })(Authentification);

const mapStateToProps = state => ({
    ...state.Auth
})
   
const mapDispatchToProps = dispatch => ({
  login: token => dispatch(login(token)),
  logout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthentificationForm);


import React, { useState } from 'react'
import {connect} from 'react-redux'
import { Input, Row, Col, Button, Icon, Upload, message, Avatar, Popconfirm, Select } from 'antd'
import { fetchAddACircuit } from '../../actions/circuits'
import Spacer from '../../components/Spacer'
import { API_UPLOAD} from '../../constants'

const { TextArea } = Input
const { Dragger } = Upload
const { Option } = Select

function AddNewTag({ fetchAddACircuit, cities, token }) {
  const [wikilocUrl, setWikilocUrl] = useState()
  const [title, setTitle] = useState({ fr: null, en: null, es: null, ar: null, zh: null })
  const [description, setDescription] = useState({ fr: null, en: null, es: null, ar: null, zh: null })
  const [media, setMedia] = useState([])
  const [city, setCity] = useState([])
 
  const handleRemoveMedia = (mediaUrl) => {
    setMedia(media.filter(m=>m.url !== mediaUrl))
  }

  function arrayMax(arr) {
    return arr.reduce(function (p, v) {
      return ( p > v ? p : v );
    });
  }

  const handleWikilocUrl = (url) => {
    if (url) {
      const numbers = (url.match(/(\d+)/g) || []);
      const urlId = arrayMax(numbers)
      const iframeUrl = `https://fr.wikiloc.com/wikiloc/spatialArtifacts.do?event=view&id=${urlId}&measures=on&title=off&near=off&images=off&maptype=H`
      setWikilocUrl(iframeUrl)
    }

  }

   // media uploads
   const dropSettings = {
    name: 'file',
    multiple: false,
    action: API_UPLOAD,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        //console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setMedia([...media, info.file.response ])
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

    return (
      <div>
        <Row gutter={16}>
          <h2>
            <Icon type="edit" /> Add a new hiking tour
          </h2>
          <Spacer top='50px' />
          <Row>
          <h1>WikiLoc URL</h1>
          <Col md={24}>
            <Input
              placeholder={"https://fr.wikiloc.com/itineraires-randonnee/ait-bouguemaz-tour-gt-dinosaures-prints-35671712"}
              onChange={event => handleWikilocUrl(event.target.value)}
            />
          </Col>
        
          </Row>
          <Spacer bottom='50px' />

          <Col md={24}>
        <h1>Select a City</h1>
        <Select
         
              style={{ width: '100%' }}
              mode="multiple"
          placeholder="Please select a city"
              onChange={(data) => {
              setCity(data)
          }}
          >
          
            {
              cities.map((p, k) => {
                return <Option key={p.title.fr + k} value={p._id}>{p.title.fr}</Option>
              })
            }
            
              
            </Select>
            <Spacer bottom='50px' />
        </Col>
        
          <Spacer bottom='50px' />
          <h1>Title</h1>
          <Col md={8}>
            <Input
              placeholder={"Français"}
              onChange={event => setTitle({ ...title, fr: event.target.value })}
            />
          </Col>
          <Col md={8}>
            <Input
              placeholder={"English"}
              onChange={event => setTitle({ ...title, en: event.target.value })}
            />
          </Col>
          <Col md={8}>
            <Input
              placeholder={"española"}
              onChange={event => setTitle({ ...title, es: event.target.value })}
            />
          </Col>
          <Col md={8}>
            <Input
              placeholder={"عربى"}
              onChange={event => setTitle({ ...title, ar: event.target.value })}
            />
          </Col>
          <Col md={8}>
            <Input
              placeholder={"Chinese"}
              onChange={event => setTitle({ ...title, zh: event.target.value })}
            />
          </Col>
        </Row>
        <Spacer top='20px' />
        <Row gutter={16}>
        
          <h1>Description</h1>

         

          <Col md={24}>
            <TextArea
              placeholder={"Français"}
              onChange={event => setDescription({ ...description, fr: event.target.value })}
            />
          </Col>
          <Col md={24}>
            <TextArea
              placeholder={"English"}
              onChange={event => setDescription({ ...description, en: event.target.value })}
            />
          </Col>
          <Col md={24}>
            <TextArea
              placeholder={"española"}
              onChange={event => setDescription({ ...description, es: event.target.value })}
            />
          </Col>
          <Col md={24}>
            <TextArea
              placeholder={"عربى"}
              onChange={event => setDescription({ ...description, ar: event.target.value })}
            />
          </Col>
          <Col md={24}>
            <TextArea
              placeholder={"Chinese"}
              onChange={event => setDescription({ ...description, zh: event.target.value })}
            />
          </Col>

        </Row>
      
        <Spacer top='20px' />
        <Row>
          <Col>
          <h1>Images</h1>
          <Dragger {...dropSettings}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Dragger>

         
          {(media && media[0] && media[0].url)&&
            media.map((m, k) => (
              <Popconfirm
                placement="topLeft"
                title={"Delete this image?"}
                onConfirm={() => handleRemoveMedia(m.url)}
                okText="Yes"
                cancelText="No"
                key={m.url}
              >
                <Avatar
                  size={64}
                  src={m.url}
                  style={{ cursor: "pointer", margin: "20px" }}
                />
              </Popconfirm>
            ))}
            </Col>
        </Row>
        

        <Button
          type="primary"
          block
          size={"large"}
          onClick={() =>
            fetchAddACircuit(token, {
              title,
              description,
              media,
              wikilocUrl,
              cities: city
            })
          }
        >
          Add
        </Button>
      </div>
    );
}


const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
    ...state.Cities
  })
  
const mapDispatchToProps = dispatch => ({
  fetchAddACircuit: (token, data) => dispatch(fetchAddACircuit(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNewTag)
  
import { SET_TOURS, ADD_TOUR } from '../actions/tours'

const initState = {
    tours: []
}
  
export default function toursReducer (state = initState, action) {
    switch (action.type) {
      case SET_TOURS:
            return {
              tours: action.payload
        }
      case ADD_TOUR:
        return {
          tours: [...state.tours, action.payload]
        }
    
      default:
        return state
    }
  }
  

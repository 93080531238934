import { API_CIRCUITS, JSON_HEADERS } from '../constants'
import { message } from 'antd';

export const SET_CIRCUITS = 'SET_CIRCUITS'
export const ADD_CIRCUIT = 'ADD_CIRCUIT'
export const UPDATE_CIRCUIT = 'UPDATE_CIRCUIT'

// setAuthToken
function setCircuits(circuits) {
    return {
        type: SET_CIRCUITS,
        payload: circuits
    }
}

function addCircuit(city) {
    return {
        type: ADD_CIRCUIT,
        payload: city
    }
}


// get Circuits from the server
export function fetchSetCircuits(token) {
    return function (dispatch) {
        return fetchApiGetCircuits(token)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setCircuits(response.data))
                    } else {
                        message.error('Something bad happens. Code: CIRCTT01')
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: CIRCTT02'))
    }
    
}

function fetchApiGetCircuits(token) {
    return fetch(API_CIRCUITS,{
        method: "GET",
        headers: {...JSON_HEADERS, Authorization: "Bearer "+ token},
    })
}


// Update a place
function fetchApiPutCircuit(token, data) {
    if (token && data && data._id) {
        return fetch(API_CIRCUITS+'/'+data._id,{
            method: "PUT",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchUpdateACircuit(token, data) {
    return function (dispatch) {
        return fetchApiPutCircuit(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(fetchSetCircuits(token))
                        message.success('This place has been updated successfuly')
                    } else {
                        message.error('Something bad happens. Code: CU01')
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: CU02'))
    }
}

// Add a new place

function fetchApiPostCircuit(token, data) {
    if (token && data) {
        return fetch(API_CIRCUITS,{
            method: "POST",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchAddACircuit(token, data) {
    return function (dispatch) {
        return fetchApiPostCircuit(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(addCircuit(response.data))
                        message.success('This city has been added successfuly')
                    } else {
                        message.error('Something bad happens. Code: CA01')
                    }
                })
            .catch(e => {
                console.log(e)
                message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: CA02')
            })
    }
}

// Remove a Tag
function fetchApiDeletePlace(token, tagId) {
    if (token && tagId) {
        return fetch(API_CIRCUITS+'/'+tagId,{
            method: "DELETE",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchRemoveACircuit(token, tagId) {
    return function (dispatch) {
        return fetchApiDeletePlace(token, tagId)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(fetchSetCircuits(token))
                        message.success('This city has been removed successfuly')
                    } else {
                        message.error('Something bad happens. Code: CD01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: CD02'))
    }
}

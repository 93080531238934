import React, { useState } from 'react'
import {connect} from 'react-redux'
import { Input, Row, Col, Button, Icon, Select  } from 'antd'
import { fetchAddAMice } from '../../actions/mice'
import Spacer from '../../components/Spacer'
import { cats, layouts, types} from './constants'
const { Option } = Select



function AddNewTag({fetchAddAMice, places, token}) {
  const [place, setPlace] = useState()
  const [cat, setCat] = useState()
  const [capacity, setCapacity] = useState()
  const [surface, setSurface] = useState()
  const [layout, setLayout] = useState()
  const [type, setType] = useState()
  const [name, setName] = useState()


    return (
      <div>
        <Row gutter={16}>
          <h2>
            <Icon type="edit" /> Add a new Mice
          </h2>
          <Spacer top="30px" />

          <Col md={12}>
            <Select
              showSearch
              style={{ width: "100%" }}
              onChange={e => setPlace(e)}
              placeholder="Select a place"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {places.map((place, i) => (
                <Option value={place._id} key={"mice-places-100" + i}>
                  {place.title.fr}
                </Option>
              ))}
            </Select>
          </Col>
          <Col md={12}>
          <Select
              showSearch
              mode="multiple"
          style={{ width: "100%" }}
          onChange={e => setCat(e)}
          placeholder="Select a category"
          filterOption={(input, option) =>
            option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {cats.map((place, i) => (
            <Option value={place.value} key={place.value + (i*10 + 1)}>
              {place.label}
            </Option>
          ))}
        </Select>
          </Col>
          <Col md={12}>
          <Select
              showSearch
              mode="multiple"
          style={{ width: "100%" }}
          onChange={e => setLayout(e)}
          placeholder="Select a Layout"
          filterOption={(input, option) =>
            option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {layouts.map((place, i) => (
            <Option value={place.value} key={place.value + (i*10 + 1)}>
              {place.label}
            </Option>
          ))}
        </Select>
          
          </Col>
          <Col md={12}>
          <Select
              showSearch
              mode="multiple"
          style={{ width: "100%" }}
          onChange={e => setType(e)}
          placeholder="Select a type"
          filterOption={(input, option) =>
            option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {types.map((place, i) => (
            <Option value={place.value} key={place.value + (i*10 + 1)}>
              {place.label}
            </Option>
          ))}
        </Select>
          </Col>
          <Col md={12}>
          
            <Input
              type={'number'}
           
          placeholder={"Capacity: how many people?"}
              onChange={event => setCapacity(event.target.value)}
        />
          
          </Col>

          <Col md={12}>
          
          <Input
            type={'number'}
         
        placeholder={"Surface in meters"}
            onChange={event => setSurface(event.target.value)}
      />
        
          </Col>
          <Col md={12}>
          
          <Input
          
         
        placeholder={"Nom de la salle"}
            onChange={event => setName(event.target.value)}
      />
        
        </Col>
        </Row>
        <Spacer top="20px" />

        <Button type="primary" block size={"large"} onClick={() => {
          fetchAddAMice(token, {place, cat, type, capacity, surface, layout, name})
        }}>
          Add
        </Button>
      </div>
    );
}


const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
  ...state.Cities,
    ...state.Mice
  })
  
const mapDispatchToProps = dispatch => ({
  fetchAddAMice: (token, data) => dispatch(fetchAddAMice(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNewTag)
  
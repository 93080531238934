import React from 'react'

export default function Spacer({ top, bottom, customb }) {
    return (
        <div>
            {top && <div style={{ paddingTop: '30px' }} />}
            {bottom && <div style={{ paddingBottom: '30px' }} />}
            {customb && <div style={{ paddingBottom: customb || 0}} />}
        
        </div>
    )
}
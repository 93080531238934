import React, { useState } from 'react'
import { connect } from 'react-redux'
import { List, Row, Col, Popconfirm, Drawer, Icon } from 'antd';
import Spacer from '../../components/Spacer'
import Layout from '../../components/Layout'
import AddNewTour from './AddNewTour'
import EditTour from './EditTour';
import {fetchRemoveATour} from '../../actions/tours'
import './tags.css'


function Tags({tours, fetchRemoveATour, places, token}) {
  const [editedItem, setEditedItem] = useState(null)
  const[drawerVisible, setDrawerVisible] = useState(false)  
  return (
      <Layout>
        <Row gutter={50}>
          <Col md={6}>
            <List
              bordered
              dataSource={tours}
              renderItem={item => (
                <List.Item>
                  
                    <div style={{ cursor: 'pointer' }} onClick={() => {
                    setEditedItem(item)
                    setDrawerVisible(true)
                  }} >
                      
                    <h3>{item.title.fr}</h3>
                  
                      
                      </div>
                    
                      <Popconfirm
                  title="Are you sure delete this tour?"
                onConfirm={() => {
                  fetchRemoveATour(token, item._id)

                }}
                  okText="Yes"
                  cancelText="No"
                >
                    <div style={{position:'absolute', right:'20px', cursor:'pointer' }}><Icon type="delete" style={{fontSize:'20px'}} /></div>
                    </Popconfirm>
                </List.Item>
              )}
            />
          </Col>
                <Col md={12}>
            <AddNewTour/>
                </Col>
            </Row>
        <Spacer top />
        
        <Drawer
          placement="right"
          width={'70vw'}
          destroyOnClose={true}
          closable={false}
          onClose={()=>{setDrawerVisible(false)}}
          visible={drawerVisible}
        >
        <EditTour data={editedItem}/>
        </Drawer>

      </Layout>
    );
}

const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
    ...state.Tags,
    ...state.Tours
  })
  
const mapDispatchToProps = dispatch => ({
  fetchRemoveATour: (token, data) => dispatch(fetchRemoveATour(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Tags)
  
import { API_PLACES, JSON_HEADERS } from '../constants'
import { message } from 'antd';

export const SET_PLACES = 'SET_PLACES'
export const ADD_PLACE = 'ADD_PLACE'
export const UPDATE_PLACE = 'UPDATE_PLACE'
export const ADD_DEAL = 'ADD_DEAL'

// setAuthToken
function setPlaces(places) {
    return {
        type: SET_PLACES,
        payload: places
    }
}

function addPlace(place) {
    return {
        type: ADD_PLACE,
        payload: place
    }
}


// get places from the server
export function fetchSetPlaces(token) {
    return function (dispatch) {
        return fetchApiGetPlaces(token)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setPlaces(response.data))
                    } else {
                        message.error('Something bad happens. Code: PE01')
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: PE02'))
    }
    
}

function fetchApiGetPlaces(token) {
    return fetch(API_PLACES+'?status=all',{
        method: "GET",
        headers: {...JSON_HEADERS, Authorization: "Bearer "+ token},
    })
}


// Update a place
function fetchApiPutPlace(token, data) {
    if (token && data && data._id) {
        return fetch(API_PLACES+'/'+data._id,{
            method: "PUT",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }


}

export function fetchUpdateAPlace(token, data) {
    return function (dispatch) {
        return fetchApiPutPlace(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(fetchSetPlaces(token))
                        message.success('This place has been updated successfuly')
                    } else {
                        message.error('Something bad happens. Code: PU01')
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: PU02'))
    }
}

// Add a new place

function fetchApiPostPlace(token, data) {
    if (token && data && data.id) {
        return fetch(API_PLACES,{
            method: "POST",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchAddAPlace(token, data) {
    return function (dispatch) {
        return fetchApiPostPlace(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(addPlace(response.data))
                        message.success('This place has been added successfuly')
                    } else {
                        message.error('Something bad happens. Code: PA01')
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: PA02'))
    }
}


//Delete Place
// Update a place
function fetchApiDeletePlace(token, data) {
    if (token && data && data._id) {
        return fetch(API_PLACES+'/'+data.id,{
            method: "DELETE",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token }
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

}

export function fetchDeleteAPlace(token, data) {
    return function (dispatch) {
        return fetchApiDeletePlace(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(fetchSetPlaces(token))
                        message.success('This place has been deleted successfuly')
                    } else {
                        message.error('Something bad happens. Code: PD01')
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: PD02'))
    }
}

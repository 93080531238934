import React, { useState } from 'react'
import {connect} from 'react-redux'
import { Input, Row, Col, Button, Icon } from 'antd'
import {fetchUpdateATag} from '../../actions/tags'


function EditTag({fetchUpdateATag, token, data}) {
  const [tagName, setTagName] = useState({ id: data.id, name: data.name, _id: data._id, slug: data.slug, order: data.order })
    return (
      <div>
        <Row gutter={16}>
          <h2>
            <Icon type="edit" /> Edit
          </h2>
          <Col md={24}>
            
            <Input
              defaultValue={data.order}
              placeholder={"Order"}
              type='number'
              onChange={event => setTagName({...tagName, order:event.target.value})}
            />
            </Col>
            <Col md={24}>
            <Input
                defaultValue={tagName.name.fr}
                placeholder={"Français"}
                onChange={event => setTagName({...tagName, name:{...tagName.name, fr:event.target.value}})}
              />
          
          </Col>
          <Col md={24}>
            
            <Input
            defaultValue={tagName.name.en}
                placeholder={"English"}
              onChange={event => setTagName({ ...tagName, name: { ...tagName.name, en: event.target.value } })}
              />
          
                </Col>
                <Col md={24}>
            
            <Input
            defaultValue={tagName.name.es}
                placeholder={"española"}
              onChange={event => setTagName({ ...tagName, name: { ...tagName.name, es: event.target.value } })}
              />
          
                </Col>
                <Col md={24}>
            
            <Input
            defaultValue={tagName.name.ar}
                  placeholder={"عربى"}
              onChange={event => setTagName({ ...tagName, name: { ...tagName.name, ar: event.target.value } })}
                />
            
                </Col>
                <Col md={24}>
            
            <Input
              defaultValue={tagName.name.zh}
                  placeholder={"Chinese"}
              onChange={event => setTagName({ ...tagName, name: { ...tagName.name, zh: event.target.value } })}
                />
            
                </Col>
                
            </Row>
        <Button type="primary" block size={'large'} onClick={() => {
          fetchUpdateATag(token,  tagName)
        }}>Update</Button>
      </div>
    );
}


const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
    ...state.Tags
  })
  
const mapDispatchToProps = dispatch => ({
  fetchUpdateATag: (token, data) => dispatch(fetchUpdateATag(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditTag)
  
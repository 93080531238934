export const cats = [
    {
      label: "Venu",
      value: "venu"
    },
    {
      label: "Accomodation",
      value: "accomodation"
    },
    {
      label: "Service",
      value: "service"
    },
    {
      label: "DMC",
      value: "dmc"
    }
  ]
  export const layouts = [
    {
      label: "Theater",
      value: "theater"
    },
    {
      label: "Classroom",
      value: "classroom"
    },
    {
      label: "Cocktail",
      value: "cocktail"
    },
    {
      label: "Meeting",
      value: "meeting"
    },
    {
      label: "In U shape",
      value: "u-shape"
    },
    {
      label: "Banquet Cabaret",
      value: "banquet-cabaret"
    },
    {
      label: "Auditorium ",
      value: "auditorium"
    }
  
  ]
  
export const types = [
  {
    label: "Meeting",
    value: "meeting"
  },
  {
    label: "Incentive",
    value: "incentive"
  },
  {
    label: "Conferencing",
    value: "conferencing"
  },
  {
    label: "Exhibition & Fair",
    value: "exhibition"
  },
    {
      label: "Congress",
      value: "congress"
    },
    {
      label: "Bordroom",
      value: "bordroom"
    },
    {
      label: "Event",
      value: "event"
    },
    {
      label: "Hotel",
      value: "hotel"
    },
    {
      label: "Restaurant",
      value: "restaurant"
    },
    {
      label: "Appartement",
      value: "appartement"
    },
    {
      label: "Furnished rental",
      value: "furnished-rental"
    },
  ]
import React, { useEffect } from "react";
import { Layout } from "antd";
import { connect } from "react-redux";
import MainMenu from "./MainMenu";
import Container from "./Container";
import Spacer from "./Spacer";
import { SITE_NAME } from "../constants";
import { fetchSetPlaces } from "../actions/places";
import { fetchSetTags } from "../actions/tags";
import { fetchSetArticles } from "../actions/articles";
import { fetchSetTours } from "../actions/tours";
import { fetchSetCities } from "../actions/city";
import { fetchSetMices } from "../actions/mice";
import { fetchSetStories } from "../actions/stories";
import { fetchSetCircuits } from '../actions/circuits'
import { fetchSetInstaWallImages } from '../actions/instawall'

const { Content, Footer } = Layout;

function LayoutComponent({
  children,
  fetchSetPlaces,
  fetchSetTags,
  fetchSetTours,
  fetchSetCities,
  fetchSetMices,
  fetchSetStories,
  fetchSetArticles,
  fetchSetCircuits,
  fetchSetInstaWallImages,
  token,
}) {
  useEffect(() => {
    if (token) {
      fetchSetPlaces(token);
    }
  }, [token, fetchSetPlaces]);

  useEffect(() => {
    if (token) {
      fetchSetArticles(token);
    }
  }, [token, fetchSetArticles]);

  useEffect(() => {
    if (token) {
      fetchSetTags(token);
    }
  }, [token, fetchSetTags]);

  useEffect(() => {
    if (token) {
      fetchSetTours(token);
    }
  }, [token, fetchSetTours]);

  useEffect(() => {
    if (token) {
      fetchSetCities(token);
    }
  }, [token, fetchSetCities]);
  useEffect(() => {
    if (token) {
      fetchSetMices(token);
    }
  }, [token, fetchSetMices]);
  useEffect(() => {
    if (token) {
      fetchSetStories(token);
    }
  }, [token, fetchSetStories]);

  useEffect(() => {
    if (token) {
      fetchSetCircuits(token);
    }
  }, [token, fetchSetCircuits]);

  useEffect(() => {
    if (token) {
      fetchSetInstaWallImages(token);
    }
  }, [token, fetchSetInstaWallImages]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <MainMenu />

      <Layout>
        <Content style={{ margin: "0 16px" }}>
          <Spacer top />
          <Container>{children}</Container>
        </Content>
        <Footer
          style={{
            textAlign: "center",
            color: "white",
            backgroundColor: "black",
            padding: "10px",
          }}
        >
          {" "}
                  © { new Date().getFullYear()} { SITE_NAME }. All rights
          reserved.{" "}
        </Footer>
      </Layout>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  ...state.Auth,
  ...state.Places,
  ...state.Tags,
  ...state.Tours
});

const mapDispatchToProps = (dispatch) => ({
  fetchSetPlaces: (token) => dispatch(fetchSetPlaces(token)),
  fetchSetTags: (token) => dispatch(fetchSetTags(token)),
  fetchSetTours: (token) => dispatch(fetchSetTours(token)),
  fetchSetCities: (token) => dispatch(fetchSetCities(token)),
  fetchSetMices: (token) => dispatch(fetchSetMices(token)),
  fetchSetArticles: (token) => dispatch(fetchSetArticles(token)),
  fetchSetStories: (token) => dispatch(fetchSetStories(token)),
  fetchSetCircuits: (token) => dispatch(fetchSetCircuits(token)),
  fetchSetInstaWallImages: (token) => dispatch(fetchSetInstaWallImages(token))
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);

import { API_MICE, JSON_HEADERS } from '../constants'
import { message } from 'antd';

export const SET_MICES = 'SET_MICES'
export const UPDATE_MICE = 'UPDATE_MICE'
export const ADD_MICE = 'ADD_MICE'
export const DELETE_MICE = 'DELETE_MICE'

// setAuthToken
function setMices(mices) {
    return {
        type: SET_MICES,
        payload: mices
    }
}



// get mices from the server
export function fetchSetMices(token) {
    return function (dispatch) {
        return fetchApiGetMices(token)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setMices(response.data))
                    } else {
                        message.error('Something bad happens. Code: ME01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: ME02'))
    }
    
}

function fetchApiGetMices(token) {
    return fetch(API_MICE ,{
        method: "GET",
        headers: {...JSON_HEADERS, Authorization: "Bearer "+ token},
    })
}


// Update a mice

function fetchApiPutMice(token, data) {
    if (token && data && data._id) {
        return fetch(API_MICE+'/'+data._id,{
            method: "PUT",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchUpdateAMice(token, data) {
    return function (dispatch) {
        return fetchApiPutMice(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(fetchSetMices(token))
                        message.success('Mice is successfuly updated');
                    } else {
                        message.error('Something bad happens. Code: MU01')
                        console.log(data)
                        console.log(response)
                    }
                })
            .catch(e => { message.error('Something bad happens. Code: MU02'); console.log(e)})
    }
}

// Add a new mice

function fetchApiPostMice(token, data) {
    if (token && data) {
        return fetch(API_MICE,{
            method: "POST",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchAddAMice(token, data) {
    return function (dispatch) {
        return fetchApiPostMice(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(fetchSetMices(token))
                        message.success('This mice has been added successfuly')
                    } else {
                        message.error('Something bad happens. Code: MA01')
                    }
                })
            .catch(e => {
                console.log(e)
                message.error('Something bad happens. Code: MA02')
            })
    }
}


// Remove a Mice
function fetchApiDeleteMice(token, miceId) {
    if (token && miceId) {
        return fetch(API_MICE+'/'+miceId,{
            method: "DELETE",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchRemoveAMice(token, miceId) {
    return function (dispatch) {
        return fetchApiDeleteMice(token, miceId)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(fetchSetMices(token))
                        message.success('This mice has been removed successfuly')
                    } else {
                        message.error('Something bad happens. Code: MD01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: MD02'))
    }
}

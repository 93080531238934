import React, { useState } from "react";
import { connect } from "react-redux";
import { fetchRemoveAStory } from "../../actions/stories";
import { List, Row, Col, Popconfirm, Drawer, Icon } from "antd";
import Spacer from "../../components/Spacer";
import Layout from "../../components/Layout";
import AddNewCity from "./AddNew";
import EditEntry from "./Edit";

import "./cities.css";

/**
 * 
 * Stories Model
 * 
  order: { **
    type: Number
  },
  isevent: {
    type: Boolean,
    default: false
  },
  city: [{ type: Schema.Types.ObjectId, ref: 'City' }],
  hashtags: {
    type: Array
  },
  time: {
    type: String
  },
  media: {
    type: Array,
    default: [{ url: '', size: '' }],
    
  }
}} 
 */

function Tags({ stories, token, fetchRemoveAStory }) {
  const [editedItem, setEditedItem] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  return (
    <Layout>
      <Row gutter={50}>
        <Col md={6}>
          <List
            bordered
            dataSource={stories}
            renderItem={(item) => (
              <List.Item>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEditedItem(item);
                    setDrawerVisible(true);
                  }}
                >
                  <h3>{item.hashtags[0]}</h3>
                </div>

                <Popconfirm
                  title="Are you sure delete this story?"
                  onConfirm={() => {
                    fetchRemoveAStory(token, item._id);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <div
                    style={{
                      position: "absolute",
                      right: "20px",
                      cursor: "pointer",
                    }}
                  >
                    <Icon type="delete" style={{ fontSize: "20px" }} />
                  </div>
                </Popconfirm>
              </List.Item>
            )}
          />
        </Col>
        <Col md={12}>
          <AddNewCity />
        </Col>
      </Row>
      <Spacer top />

      <Drawer
        placement="right"
        width={"30vw"}
        destroyOnClose={true}
        closable={false}
        onClose={() => {
          setDrawerVisible(false);
        }}
        visible={drawerVisible}
      >
        <EditEntry data={editedItem} />
      </Drawer>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  ...state.Auth,
  ...state.Places,
  ...state.Tags,
  ...state.Stories,
});

const mapDispatchToProps = (dispatch) => ({
  fetchRemoveAStory: (token, id) => dispatch(fetchRemoveAStory(token, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);

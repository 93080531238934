import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Input, Row, Col, Select, Checkbox , Button, Icon, Upload, message, Avatar, Popconfirm, DatePicker } from 'antd'
import { fetchUpdateAStory } from '../../actions/stories'
import Spacer from '../../components/Spacer'
import { API_UPLOAD} from '../../constants'


const { Dragger } = Upload;
const { Option } = Select;



function EditTag({fetchUpdateAStory, token, data, cities}) {
  const [tagName, setTagName] = useState({ ...data })
  const [media, setMedia] = useState(tagName?.media)

  
const handleRemoveMedia = (mediaUrl) => {
  setMedia(media.filter(m=>m.url !== mediaUrl))
}


 // media uploads
 const dropSettings = {
  name: 'file',
  multiple: false,
  action: API_UPLOAD,
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      //console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      setMedia([info.file.response ])
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

  
  
    return (
      <div>
      <Row gutter={16}>
      <h2>
            <Icon type="edit" /> Edit
          
      </h2>
          <Spacer top='50px' />
          <Checkbox
            defaultChecked={tagName.isevent}
            onChange={(e) => setTagName({...tagName, isevent: e.target.checked})}>Event?</Checkbox>

      <h1>Order</h1>
      <Input
      defaultValue={tagName.order}
  placeholder={"Order"}
      onChange={event => setTagName({ ...tagName, order: event.target.value })}
/>
      <Spacer top='50px' />
      <h1>When?</h1>
          <Col md={8}>
            <DatePicker
              showTime
              onChange={event => setTagName({ ...tagName, time: event })}
              defaultValue={moment(tagName.time)}
            />
            
        
            
            </Col>
    </Row>
    <Spacer top='20px' />
    <Row gutter={16}>
    
      <h1>Select a City</h1>

     

      <Col md={24}>
      <Select
         
         style={{ width: '100%' }}
              placeholder="Please select a city"
              defaultOpen={tagName.city}
             onChange={(data) => {
             setTagName({...tagName, city: data})
         }}
         >
         
           {
             cities.map((p, k) => {
               return <Option key={p.title.fr + k} value={p._id}>{p.title.fr}</Option>
             })
           }
           
       </Select>
      </Col>
      

    </Row>
    <Spacer top='20px' />
    <Row gutter={16}>
      <h1>Hashtags</h1>
    <Col md={8}>
            <Input
            defaultValue={tagName.hashtags[0]}
      placeholder={"Hashags"}
      onChange={event => setTagName({ ...tagName, hashtags: [event.target.value, tagName.hashtags[1]] })}
      />
</Col>
<Col md={8}>
<Input
            defaultValue={tagName.hashtags[1]}
      placeholder={"Hashags"}
      onChange={event => setTagName({ ...tagName, hashtags: [tagName.hashtags[0], event.target.value] })}
      />
</Col>
        </Row>
        
        <Spacer top='20px' />
        <Row>
          <Col>
          <h1>Images</h1>
          <Dragger {...dropSettings}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Dragger>

         
          {(media && media[0] && media[0].url)&&
            media.map((m, k) => (
              <Popconfirm
                placement="topLeft"
                title={"Delete this image?"}
                onConfirm={() => handleRemoveMedia(m.url)}
                okText="Yes"
                cancelText="No"
                key={m.url}
              >
                <Avatar
                  size={64}
                  src={m.url}
                  style={{ cursor: "pointer", margin: "20px" }}
                />
              </Popconfirm>
            ))}
            </Col>
        </Row>


        <Button type="primary" block size={'large'} onClick={() => {
          console.log('query', {...tagName, media})
          fetchUpdateAStory(token,  {...tagName, media})
        }}>Update</Button>
      </div>
    );
}


const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
    ...state.Cities
  })
  
const mapDispatchToProps = dispatch => ({
  fetchUpdateAStory: (token, data) => dispatch(fetchUpdateAStory(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditTag)
  
import React, { useState } from 'react'
import {connect} from 'react-redux'
import { Input, Row, Col, Button, Icon, Upload, message, Avatar, Popconfirm } from 'antd'
import { fetchAddACity } from '../../actions/city'
import Spacer from '../../components/Spacer'
import {slugify, API_UPLOAD} from '../../constants'

const { TextArea } = Input
const { Dragger } = Upload;

function AddNewTag({fetchAddACity, token}) {
  const [title, setTitle] = useState({ fr: null, en: null, es: null, ar: null, zh: null })
  const [description, setDescription] = useState({ fr: null, en: null, es: null, ar: null, zh: null })
  const [media, setMedia] = useState([])
  const [order, setOrder] = useState(0)
  const [coordinates, setCoordinates] = useState([])
  const handleRemoveMedia = (mediaUrl) => {
    setMedia(media.filter(m=>m.url !== mediaUrl))
  }


   // media uploads
   const dropSettings = {
    name: 'file',
    multiple: false,
    action: API_UPLOAD,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        //console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setMedia([...media, info.file.response ])
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

    return (
      <div>
        <Row gutter={16}>
          <h2>
            <Icon type="edit" /> Add a new City
          </h2>
          <Spacer top='50px' />
          <h1>Display order</h1>
          <Input
            type='number'
          placeholder={"Order"}
          onChange={event => setOrder(event.target.value)}
          />
          <Spacer top='50px' />
          <h1>Title</h1>
          <Col md={8}>
            <Input
              placeholder={"Français"}
              onChange={event => setTitle({ ...title, fr: event.target.value })}
            />
          </Col>
          <Col md={8}>
            <Input
              placeholder={"English"}
              onChange={event => setTitle({ ...title, en: event.target.value })}
            />
          </Col>
          <Col md={8}>
            <Input
              placeholder={"española"}
              onChange={event => setTitle({ ...title, es: event.target.value })}
            />
          </Col>
          <Col md={8}>
            <Input
              placeholder={"عربى"}
              onChange={event => setTitle({ ...title, ar: event.target.value })}
            />
          </Col>
          <Col md={8}>
            <Input
              placeholder={"Chinese"}
              onChange={event => setTitle({ ...title, zh: event.target.value })}
            />
          </Col>
        </Row>
        <Spacer top='20px' />
        <Row gutter={16}>
        
          <h1>Description</h1>

         

          <Col md={24}>
            <TextArea
              placeholder={"Français"}
              onChange={event => setDescription({ ...description, fr: event.target.value })}
            />
          </Col>
          <Col md={24}>
            <TextArea
              placeholder={"English"}
              onChange={event => setDescription({ ...description, en: event.target.value })}
            />
          </Col>
          <Col md={24}>
            <TextArea
              placeholder={"española"}
              onChange={event => setDescription({ ...description, es: event.target.value })}
            />
          </Col>
          <Col md={24}>
            <TextArea
              placeholder={"عربى"}
              onChange={event => setDescription({ ...description, ar: event.target.value })}
            />
          </Col>
          <Col md={24}>
            <TextArea
              placeholder={"Chinese"}
              onChange={event => setDescription({ ...description, zh: event.target.value })}
            />
          </Col>

        </Row>
        <Spacer top='20px' />
        <Row gutter={16}>
          <h1>Position</h1>
        <Col md={8}>
        <Input
          placeholder={"Latitude"}
          onChange={event => setCoordinates({ ...coordinates, latitude: event.target.value })}
      />
    </Col>
    <Col md={8}>
      <Input
        placeholder={"Longitude"}
        onChange={event => setCoordinates({ ...coordinates, longitude: event.target.value })}
      />
          </Col>
          
          

        </Row>
        <Spacer top='20px' />
        <Row>
          <Col>
          <h1>Images</h1>
          <Dragger {...dropSettings}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Dragger>

         
          {(media && media[0] && media[0].url)&&
            media.map((m, k) => (
              <Popconfirm
                placement="topLeft"
                title={"Delete this image?"}
                onConfirm={() => handleRemoveMedia(m.url)}
                okText="Yes"
                cancelText="No"
                key={m.url}
              >
                <Avatar
                  size={64}
                  src={m.url}
                  style={{ cursor: "pointer", margin: "20px" }}
                />
              </Popconfirm>
            ))}
            </Col>
        </Row>
        

        <Button
          type="primary"
          block
          size={"large"}
          onClick={() =>
            fetchAddACity(token, {
              slug: slugify(title.fr),
              title,
              description,
              coordinates, 
              order,
              media
            })
          }
        >
          Add
        </Button>
      </div>
    );
}


const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
    ...state.Cities
  })
  
const mapDispatchToProps = dispatch => ({
  fetchAddACity: (token, data) => dispatch(fetchAddACity(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNewTag)
  
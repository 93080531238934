import React, { useState } from 'react'
import EditPlace from './EditPlace'
import {NO_IMAGE} from '../../constants'
import { List, Avatar, Drawer, Icon } from 'antd';

const MissingTags = ({item}) => {
  if (!item || !item.tags || !item.tags[0] || item.tags[0] === '') {
    return <p style={{color:'red'}}><Icon type="warning" />  Missing tags</p>
  }

  return <p></p>
}

const MissingTanslations = ({item}) => {
  if (!item || !item.title || !item.title['fr'] || !item.title['en'] || !item.title['es'] || !item.title['ar'] || !item.title['zh']
  || !item.description || !item.description['fr'] || !item.description['en'] ||  !item.description['es'] || !item.description['ar'] || !item.description['zh']
  ) {
    return <p style={{color:'#47B1FF', opacity:'0.5'}}><Icon type="warning" />  Missing Translations</p>
  }

  return <p></p>
}

export default function TableView({ data }) {

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [activePlace, setActivePlace] = useState(null)
 


  return (
    <div>
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          pageSize:15
        }}
        dataSource={data}
       
        renderItem={item => (
          <List.Item
            onClick={() => {
              setActivePlace(item); //Ok
              setDrawerVisible(true)
            }}
            key={item.title.fr + Date.now()}
            actions={[
              <div><MissingTanslations item={item}/><MissingTags item={item}/></div>
            ]}
           

            extra={
              <img
             
                width={272}
                alt="media"
                src={item.media[0] ? item.media[0].url : NO_IMAGE}
              />
            }
           
          >
            <List.Item.Meta
            style={{border:item.status === 'draft' ? '10px dashed #f0f2f5' : '0', padding: item.status === 'draft' ? '20px' : '0'}}
              avatar={<Avatar src={item.media[0] ? item.media[0].url : NO_IMAGE} />}
              title={<div style={{'cursor':'pointer'}} onClick={() => {
                setActivePlace(item); //Ok
                setDrawerVisible(true)
              }}>{ (item.status === 'draft' ? '  ___draft:  ' : '') + item.title.fr }</div>}
              description={item.description.fr}
            />
           
            
          </List.Item>

          
          
        )}
      />

      <Drawer
      placement="right"
        width={'80vw'}
        destroyOnClose={true}
        closable={false}
        onClose={()=>{setDrawerVisible(false)}}
        visible={drawerVisible}
      >
      <EditPlace activePlace={activePlace} />
    </Drawer>
     

      </div>
      
      )
    
}
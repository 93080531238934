import { API_ARTICLES, JSON_HEADERS } from '../constants'
import { message } from 'antd';

export const SET_ARTICLES = 'SET_ARTICLES'
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE'
export const ADD_ARTICLE = 'ADD_ARTICLE'
export const DELETE_ARTICLE = 'DELETE_ARTICLE'

// setAuthToken
function setArticles(articles) {
    return {
        type: SET_ARTICLES,
        payload: articles
    }
}

function addArticle(article) {
    return {
        type: ADD_ARTICLE,
        payload: article
    }
}



// get articles from the server
export function fetchSetArticles(token) {
    return function (dispatch) {
        return fetchApiGetArticles(token)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setArticles(response.data))
                    } else {
                        message.error('Something bad happens. Code: AE01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: AE02'))
    }
    
}

function fetchApiGetArticles(token) {
    return fetch(API_ARTICLES ,{
        method: "GET",
        headers: {...JSON_HEADERS, Authorization: "Bearer "+ token},
    })
}


// Update a article

function fetchApiPutArticle(token, data) {
    if (token && data && data._id) {
        return fetch(API_ARTICLES+'/'+data._id,{
            method: "PUT",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchUpdateAArticle(token, data) {
    return function (dispatch) {
        return fetchApiPutArticle(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(fetchSetArticles(token))
                        message.success('Article is successfuly updated');
                    } else {
                        message.error('Something bad happens. Code: AU01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: AU02'))
    }
}

// Add a new article

function fetchApiPostArticle(token, data) {
    if (token && data) {
        return fetch(API_ARTICLES,{
            method: "POST",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
            body: JSON.stringify(data)
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchAddAArticle(token, data) {
    return function (dispatch) {
        return fetchApiPostArticle(token, data)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(addArticle(response.data))
                        message.success('This article has been added successfuly')
                    } else {
                        message.error('Something bad happens. Code: AA01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: AA02'))
    }
}


// Remove a Article
function fetchApiDeleteArticle(token, articleId) {
    if (token && articleId) {
        return fetch(API_ARTICLES+'/'+articleId,{
            method: "DELETE",
            headers: { ...JSON_HEADERS, Authorization: "Bearer " + token },
        })
    } else {
        return Promise.reject(new Error('Missing Data'))
    }

    
}

export function fetchRemoveAArticle(token, articleId) {
    return function (dispatch) {
        return fetchApiDeleteArticle(token, articleId)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data && response.status === 'success') {
                        dispatch(fetchSetArticles(token))
                        message.success('This article has been removed successfuly')
                    } else {
                        message.error('Something bad happens. Code: AD01')
                    }
                })
                .catch(e=>message.error('Something bad happens. Code: AD02'))
    }
}

import { SET_ARTICLES, ADD_ARTICLE,  } from '../actions/articles'

const initState = {
    articles: []
}
  
export default function articlesReducer (state = initState, action) {
    switch (action.type) {
      case SET_ARTICLES:
            return {
              articles: action.payload
        }
      case ADD_ARTICLE:
        return {
          articles: [...state.articles, action.payload]
        }
    
      default:
        return state
    }
  }
  

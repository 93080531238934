import { message } from 'antd';
import { API_AUTH, JSON_HEADERS } from '../constants'


export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
export const UNSET_AUTH_TOKEN = 'UNSET_AUTH_TOKEN'

// setAuthToken
function setAuthToken(token) {
    return {
        type: SET_AUTH_TOKEN,
        payload: token
    }
}

// UnsetAuthToken
export function logout() {
    return {
        type: UNSET_AUTH_TOKEN,
    }
}

// login
export function login({ username, password }) {
    return function (dispatch) {
        return fetchApiAuth({ username, password })
                .then(function(res){ return res.json(); })
                .then(function (data) {
                    if (data && data.token) {
                        dispatch(setAuthToken(data.token))
                        message.success('Welcome back '+ username);
                    } else {
                        message.error('Incorrect Username or Password')
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it.'))
    }
    
}

function fetchApiAuth({ username, password }) {
    return fetch(API_AUTH,{
        method: "POST",
        headers: JSON_HEADERS,
        body: JSON.stringify({ username, password })
    })
}

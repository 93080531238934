import React, {useState, useEffect} from "react"
import { connect } from "react-redux"
import { Button, Drawer, Icon, Input } from 'antd'
import FlexSearch from 'flexsearch/dist/flexsearch.min'
import ListView from "./ListView"
import Layout from "../../components/Layout"
import Spacer from "../../components/Spacer"
import AddAPlace from './AddAPlace'
import "./places.css"


const { Search } = Input

var index = new FlexSearch({
  profile:"fast",
  depth: 5,
  async: true
});

let isFired = false

export function idToAPlace(id, places) {
  if (!places) return
  
  if (id && Array.isArray(id)) {
      return places.filter(m=>id.indexOf(m.id)>-1)
  }
  return places.filter(m=>m.id===id)[0]
}


function Places({ places, tags }) {
  
  const [showAddPlace, setShowAddPlace] = useState(false)
  const [searchQuery, setSearchQuery] = useState(null)
  const [results, setResults] = useState(null)

   // HandleSearch
  useEffect(() => {
    if (!isFired) {
      for (let i = 0; i < places.length; i++){
        const {  id, contact, status, location, description, title, subtitle, media, tags, misc } = places[i]
        index.add(id, JSON.stringify({  id, contact, status, location, description, title, subtitle, media, tags, misc }))
        if (i === places.length - 1) {
          isFired = true
        }
      }
    }
  }, [places])
  
  useEffect(() => {
    if (searchQuery) {
      index.search(searchQuery, {
        limit: 20
      }, function (results) {
          const placesResults = results.map((r) => {
            return idToAPlace(r, places)
          })       
          setResults(placesResults)
    });
    }   
  }, [searchQuery, places])
  // End HandleSearch

 
  return (
    <Layout>
      <div style={{position:'fixed', bottom:'10px', right:'10px', zIndex:100, display:showAddPlace ? 'none' : 'block'}}>
        <Button type={'primary'} shape={'circle'} style={{ width: '90px', height: '90px' }} className={'shadow'} onClick={() => setShowAddPlace(true)}><Icon style={{fontSize:'50px'}} type="plus" /></Button>
      
      </div>

      <Search
        placeholder={"Search for a place, or any thing else...You can type for example: draft to display unpublished places"}
        onSearch={value => setSearchQuery(value)}
        style={{ width: '100%' }}
      />

      <div style={{ paddingTop: '10px', display: 'flex', maxWidth:'100%', flexWrap:'wrap' }} className={'places-buttons'}>
      <Button type={'primary'} onClick={() => {
        setResults(places)
        setSearchQuery(null)
        }}>All</Button>
        
      <Button type={'primary'} onClick={() => {
        const drafts = places.filter(p => p.status === 'draft')
        setResults(drafts)
      }}>Drafts</Button>

      <Button type={'primary'} onClick={() => {
        const featured = places.filter(p => p.featured)
        setResults(featured)
        }}>Featured</Button>
        
        {
          tags.map((t) => {
            return (
              <Button type={'secondary'} key={Math.random()*Date.now()} onClick={() => {
                const featured = places.filter(p => p.tags.map(e=>e.slug).indexOf(t.slug)>-1)
                setResults(featured)
              }}>{t.name.fr}</Button>
            )
          })
        }
        

      </div>
      <Spacer bottom />

      {places && (
        <div className="places">
          <ListView data={results ? results: places} />
        </div>
      )}

      <Drawer
          title="New place"
          placement="right"
          closable={false}
        destroyOnClose
        width={'80vw'}
          onClose={()=>setShowAddPlace(false)}
          visible={showAddPlace}
        >
          <AddAPlace/>
        </Drawer>
      <Spacer bottom />
    </Layout>
  )
}

const mapStateToProps = state => ({
  ...state.Auth,
  ...state.Places,
  ...state.Tags
})

const mapDispatchToProps = dispatch => ({
  
})

export default connect(mapStateToProps, mapDispatchToProps)(Places)

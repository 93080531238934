import { SET_STORIES, ADD_STORY  } from '../actions/stories'

const initState = {
    stories: []
}
  
export default function tagsReducer (state = initState, action) {
    switch (action.type) {
      case SET_STORIES:
            return {
                stories: action.payload
        }
      case ADD_STORY:
        return {
            stories: [...state.stories, action.payload]
        }
    
      default:
        return state
    }
  }
  

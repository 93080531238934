import { SET_PLACES, ADD_PLACE } from '../actions/places'

const initState = {
  places: [],
  deals: []
}
  
export default function poiReducer (state = initState, action) {
  switch (action.type) {
    case SET_PLACES:
      const allPlaces = action.payload;
      const deals = allPlaces.filter(p => p.isDeal);
      const nonDeals = allPlaces.filter(p => !p.isDeal);
      return {
        places: nonDeals,
        deals: deals
      };
    case ADD_PLACE:
      const thePlace = action.payload;
      if (thePlace.isDeal) {
        return {
          places: [...state.places],
          deals: [...state.deals, action.payload]
        }
      } else {
        return {
          places: [...state.places, action.payload],
          deals:[...state.deals]
        };
      }
   
    default:
      return state;
  }
  }
  

import React, {useState} from 'react'
import { connect } from 'react-redux'
import { List, Row, Col, Popconfirm, Badge, message, Drawer, Icon } from 'antd';
import Spacer from '../../components/Spacer'
import Layout from '../../components/Layout'
import AddNewTag from './AddNewTag'
import EditTag from './EditTag'
import {fetchRemoveATag} from '../../actions/tags'
import './tags.css'


const tagSlugToPlacesCount = (slug, places) => {
  /* eslint-disable-next-line */
  const groupedPlacesByTagId = places.filter(p => {
    if (p.tags) {
      const onlySlugs = p.tags.map(p => p.slug)
      return onlySlugs.indexOf(slug) >-1
    }

  })

  return groupedPlacesByTagId.length
}

function Tags({ tags, token, fetchRemoveATag, places }) {
  const [editedItem, setEditedItem] = useState(null)
  const[drawerVisible, setDrawerVisible] = useState(false)
    return (
      <Layout>
        <Row gutter={50}>
          <Col md={6}>
            <List
              bordered
              dataSource={tags}
              renderItem={item => (
                <List.Item>
                 
                  <div style={{ cursor: 'pointer' }} onClick={() => {
                    setEditedItem(item)
                    setDrawerVisible(true)
                  }}>
                      <Badge count={tagSlugToPlacesCount(item.slug, places)} style={{marginRight:'-20px', backgroundColor:'#1890ff'}}>
                        <h3>{item.name.fr}</h3>
                        
                    </Badge>
                    
                    </div>
                    
                 
                  <Popconfirm
                  title="Are you sure delete this tag?"
                onConfirm={() => {
                  if (tagSlugToPlacesCount(item.slug, places) > 0) {
                    message.error('You cannot delete this Tag before removing it from places.')
                  } else {
                    fetchRemoveATag(token, item._id)
                  }
                  
                }}
                  okText="Yes"
                  cancelText="No"
                >
                    <div style={{position:'absolute', right:'20px', cursor:'pointer' }}><Icon type="delete" style={{fontSize:'20px'}} /></div>
                    </Popconfirm>
                </List.Item>
              )}
            />

           
            
          </Col>
          <Col md={12}>
          
            

            <AddNewTag/>
          </Col>
          
         
          
          

            </Row>
        <Spacer top />
        

        <Drawer
          placement="right"
          width={'70vw'}
          destroyOnClose={true}
          closable={false}
          onClose={()=>{setDrawerVisible(false)}}
          visible={drawerVisible}
        >
        <EditTag data={editedItem}/>
        </Drawer>
        

      </Layout>
    );
}

const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
    ...state.Tags
  })
  
const mapDispatchToProps = dispatch => ({
  fetchRemoveATag: (token, data) => dispatch(fetchRemoveATag(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Tags)
  
import { SET_MICES, ADD_MICE  } from '../actions/mice'

const initState = {
    mices: []
}
  
export default function miceReducer (state = initState, action) {
    switch (action.type) {
      case SET_MICES:
            return {
              mices: action.payload
        }
      case ADD_MICE:
        return {
          mices: [...state.mices, action.payload]
        }
    
      default:
        return state
    }
  }
  

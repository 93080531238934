import React, { useState } from 'react'
import {connect} from 'react-redux'
import { Input, Row, Col, Button, Icon, Select, message } from 'antd'
import { fetchAddATour } from '../../actions/tours'

const { Option } = Select;
const { TextArea } = Input

const defaultState = {title:{ fr: null, en: null, es: null, ar: null, zh: null }, description:{ fr: null, en: null, es: null, ar: null, zh: null },places: []}

const prepareDataBeforesend = (tourDetails) => {

   
  if (!tourDetails || !tourDetails.title || !tourDetails.title.fr) {
      message.error('You need at least add French tour Name')
      return
  }
  
  if (!tourDetails || !tourDetails.places || !tourDetails.places[1]) {
      message.error('You need at least to add 2 points')
      return
  }

  return tourDetails
    
}

function AddNewTour({fetchAddATour, token, places}) {
  const [tourDetails, setTourDetails] = useState(defaultState)
    return (
      <div>
        <Row gutter={16}>
          <h2>
            <Icon type="edit" /> Title
          </h2>
          <Col md={24}>
            
              <Input
                placeholder={"Français"}
                onChange={event => {
                let newTourDetails = { ...tourDetails }
                newTourDetails.title['fr'] = event.target.value
                setTourDetails(newTourDetails)
              }}
              />
          
          </Col>
          <Col md={24}>
            
              <Input
                placeholder={"English"}
                onChange={event => {
                  let newTourDetails = { ...tourDetails }
                  newTourDetails.title['en'] = event.target.value
                  setTourDetails(newTourDetails)
                }}
              />
          
                </Col>
                <Col md={24}>
            
              <Input
                placeholder={"española"}
                onChange={event => {
                  let newTourDetails = { ...tourDetails }
                  newTourDetails.title['es'] = event.target.value
                  setTourDetails(newTourDetails)
                }}
              />
          
                </Col>
                <Col md={24}>
            
                <Input
                  placeholder={"عربى"}
                  onChange={event => {
                    let newTourDetails = { ...tourDetails }
                    newTourDetails.title['ar'] = event.target.value
                    setTourDetails(newTourDetails)
                  }}
                />
            
                </Col>
                <Col md={24}>
            
                <Input
                  placeholder={"Chinese"}
                  onChange={event => {
                    let newTourDetails = { ...tourDetails }
                    newTourDetails.title['zh'] = event.target.value
                    setTourDetails(newTourDetails)
                  }}
                />
            
          </Col>
          
         
                
        </Row>




        <Row gutter={16}>
        <h2>
          <Icon type="edit" /> Description
        </h2>
        <Col md={24}>
          
            <TextArea
              placeholder={"Français"}
            onChange={event => {
              let newTourDetails = { ...tourDetails }
              newTourDetails.description['fr'] = event.target.value
              setTourDetails(newTourDetails)
            }}
            />
        
        </Col>
        <Col md={24}>
          
            <TextArea
              placeholder={"English"}
              onChange={event => {
                let newTourDetails = { ...tourDetails }
                newTourDetails.description['en'] = event.target.value
                setTourDetails(newTourDetails)
              }}
            />
        
              </Col>
              <Col md={24}>
          
            <TextArea
              placeholder={"española"}
              onChange={event => {
                let newTourDetails = { ...tourDetails }
                newTourDetails.description['es'] = event.target.value
                setTourDetails(newTourDetails)
              }}
            />
        
              </Col>
              <Col md={24}>
          
              <TextArea
                placeholder={"عربى"}
                onChange={event => {
                  let newTourDetails = { ...tourDetails }
                  newTourDetails.description['ar'] = event.target.value
                  setTourDetails(newTourDetails)
                }}
              />
          
              </Col>
              <Col md={24}>
          
              <TextArea
                placeholder={"Chinese"}
                onChange={event => {
                  let newTourDetails = { ...tourDetails }
                  newTourDetails.description['zh'] = event.target.value
                  setTourDetails(newTourDetails)
                }}
              />
          
        </Col>
        
       
              
      </Row>



       
        <Row>
        <Col md={24}>
        <h2>
        <Icon type="edit" /> Places
      </h2>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
              placeholder="Please select"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            onChange={(data) => {
              let newTourDetails = { ...tourDetails }
              newTourDetails.places = [...data]
              setTourDetails(newTourDetails)
          }}
          >
          
            {
              places.map((p, k) => {
                return <Option key={p.title.fr + k} value={p._id}>{p.title.fr}</Option>
              })
            }
            
        </Select>
        </Col>
        
        </Row>
        <Button type="primary" block size={'large'} onClick={() => {
          //console.log((prepareDataBeforesend(tourDetails)))
          fetchAddATour(token, prepareDataBeforesend(tourDetails))
        }}>Add</Button>
      </div>
    );
}


const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
    ...state.Tags
  })
  
const mapDispatchToProps = dispatch => ({
  fetchAddATour: (token, data) => dispatch(fetchAddATour(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNewTour)
  
import React, { useState } from 'react'
import {connect} from 'react-redux'
import { Input, Row, Col, Button, Icon, Select  } from 'antd'
import { fetchUpdateAMice } from '../../actions/mice'
import Spacer from '../../components/Spacer'
import { cats, layouts, types} from './constants'

const { Option } = Select

function EditTag({fetchUpdateAMice, token, data, places}) {
    const [editableMice, setEditableMice] = useState({...data, place: data.place._id})
    return (
      <div>
      <Row gutter={16}>
      <h2>
        <Icon type="edit" /> Edit
      </h2>
      <Spacer top='50px' />
      <Col md={12}>
            <Select
              showSearch
              style={{ width: "100%" }}
              onChange={e => setEditableMice({...editableMice, place: e})}
              placeholder="Select a place"
              defaultValue={editableMice.place}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {places.map((place, i) => (
                <Option value={place._id} key={"mice-places-100" + i}>
                  {place.title.fr}
                </Option>
              ))}
            </Select>
          </Col>
          <Col md={12}>
          <Select
              showSearch
              defaultValue={editableMice.cat}
              mode="multiple"
              style={{ width: "100%" }}
              onChange={e => setEditableMice({...editableMice, cat: e})}
         
          placeholder="Select a category"
          filterOption={(input, option) =>
            option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {cats.map((place, i) => (
            <Option value={place.value} key={place.value + (i*10 + 1)}>
              {place.label}
            </Option>
          ))}
        </Select>
          </Col>
          <Col md={12}>
          <Select
              showSearch
              defaultValue={editableMice.layout}
              mode="multiple"
          style={{ width: "100%" }}
          onChange={e => setEditableMice({...editableMice, layout: e})}

          placeholder="Select a Layout"
          filterOption={(input, option) =>
            option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {layouts.map((place, i) => (
            <Option value={place.value} key={place.value + (i*10 + 1)}>
              {place.label}
            </Option>
          ))}
        </Select>
          
          </Col>
          <Col md={12}>
          <Select
              showSearch
              defaultValue={editableMice.type}

              mode="multiple"
              style={{ width: "100%" }}
              onChange={e => setEditableMice({...editableMice, type: e})}

          placeholder="Select a type"
          filterOption={(input, option) =>
            option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {types.map((place, i) => (
            <Option value={place.value} key={place.value + (i*10 + 1)}>
              {place.label}
            </Option>
          ))}
        </Select>
          </Col>
          <Col md={12}>
          
            <Input
              type={'number'}
              defaultValue={editableMice.capacity}

              onChange={e => setEditableMice({...editableMice, capacity: e.target.value})}

          placeholder={"Capacity: how many people?"}
          
        />
          
          </Col>

          <Col md={12}>
          
          <Input
            type={'number'}
            defaultValue={editableMice.surface}

        placeholder={"Surface in meters"}
            
              onChange={e => setEditableMice({...editableMice, surface: e.target.value})}

      />
        
          </Col>
          
          <Col md={12}>
          
          <Input
          
            defaultValue={editableMice.name}

        placeholder={"Nom de la salle"}
            
              onChange={e => setEditableMice({...editableMice, name: e.target.value})}

      />
        
        </Col>
    </Row>
        <Button type="primary" block size={'large'} onClick={() => {
          fetchUpdateAMice(token,  editableMice)
        }}>Update</Button>
      </div>
    );
}


const mapStateToProps = state => ({
    ...state.Auth,
    ...state.Places,
  ...state.Tags,
    ...state.Mice
  })
  
const mapDispatchToProps = dispatch => ({
  fetchUpdateAMice: (token, data) => dispatch(fetchUpdateAMice(token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditTag)
  